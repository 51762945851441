export const PUBLIC_ROUTE = {
  LANDING: '/',
  GET_STARTED: '/signup',
  SIGNIN: '/',
  SETUP_WALLET: '/signup/setupwallet',
  CREATE_WALLET_INFO: '/signup/createwalletinfo',
  CREATE_WALLET_NAME: '/signup/createwalletname',
  CREATE_WALLET_PHRASE: '/signup/createwalletphrase',
  WALLET_PHRASE_CONFIRMATION: '/signup/walletphraseconfirmation',
  CREATED_WALLET_CONFIRMATION: '/signup/createdwalletconfirmation',
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  NEXT_BRIDGE: '/nextbridge',
  FORGOT_PASSWORD: '/forgotpassword',
  FORGOT_PASSWORD_EMAIL_SENT: '/forgotpasswordemailsent',
  RESET_PASSWORD: '/resetpassword',
  RESET_PASSWORD_COMPLETE: '/resetpasswordcomplete',
  PAGE_404: '/404',
  PAGE_500: '/500',
  AUTH0_CALLBACK: '/auth0loginCallback',
  ARTIFACT_DETAILS: '/artifact/details',
  FANFINGERS: '/fanfingers',
  FANFINGERS_LANDING: '/fanfingers/',
};

export const PROJECT_ROUTE = {
  STOREFRONT: '/projects',
  STOREFRONT_LANDING: '/projects/',
  PROJECT: '/project',
  PROJECT_LANDING: '/project/',
  PROJECT_PREVIEW: '/project/preview/',
  PROJECT_DETAILS: '/project/details',

  ARTIFACT: '/artifact',
  ARTIFACT_LANDING: '/artifact/',
};

export const PRIVATE_ROUTE = {
  DASHBOARD: '/dashboard',
  DASHBOARD_LANDING: '/dashboard/',
  DASHBOARD_HOME: '/dashboard/home',
  DASHBOARD_PROJECTS: '/dashboard/projects',
  DASHBOARD_ASSETS: '/dashboard/assets',
  DASHBOARD_LANDING_PAGES: '/dashboard/landingpages',
  DASHBOARD_FUNDS: '/dashboard/funds',
  DASHBOARD_FANS: '/dashboard/fans',
  DASHBOARD_MARKET_PLACE: '/dashboard/marketplace',
  DASHBOARD_MY_ACCOUNT: '/dashboard/myaccount',

  DASHBOARD_CREATE_PROJECT: '/dashboard/project',
  DASHBOARD_CREATE_ARTIFACT: '/dashboard/artefact',

  PROJECTS_SETUP_PROJECT: '/dashboard/project/',
  PROJECTS_SETUP_PROJECT_BASIC_DETAILS: '/dashboard/project/setupproject',
  PROJECTS_SETUP_BACKER_TOKEN: '/dashboard/project/setupbackertoken',
  PROJECTS_SETUP_ROYALTY_SPLIT: '/dashboard/project/setuproyaltysplit',
  PROJECTS_MINT_BACKERS_TOKEN: '/dashboard/project/mintbackerstoken',
  PROJECTS_CREATED_BACKERS_TOKEN: '/dashboard/project/createdbackerstoken',
  PROJECTS_CREATE_ARTIFACT: '/dashboard/project/createartifact',
  PROJECTS_CREATE_ARTIFACT_BULK: '/dashboard/project/fanthumb',
  PROJECTS_SETUP_LANDING: '/dashboard/project/setuplandingpage/:',
  PROJECTS_SETUP_LANDING_PAGE: '/dashboard/project/setuplandingpage/:projectId',

  MAIL: '/inbox',
  SCRUM_BOARD: '/scrum-board',
  CALENDAR: '/calendar',
  GOOGLE_MAP: '/googlemap',
  LEAFLET_MAP: '/leafletmap',
  TABLE: '/table_ant',
  FORM: '/allFormComponent',
  INPUT: '/InputField',
  EDITOR: '/editor',
  FORM_WITH_STEPPERS: '/stepperForms',
  FORM_WITH_VALIDATION: '/FormsWithValidation',
  PROGRESS: '/progress',
  BUTTON: '/button',
  TAB: '/tab',
  AUTOCOMPLETE: '/autocomplete',
  CHECKBOX: '/checkbox',
  RADIOBOX: '/radiobox',
  SELECT: '/selectbox',
  TRANSFER: '/transfer',
  GRID_LAYOUT: '/gridLayout',
  NOTES: '/notes',
  TODO: '/todo',
  ARTICLES: '/articles',
  INVESTORS: '/investors',
  CONTACTS: '/contacts',
  ALERT: '/alert',
  MODAL: '/modal',
  MESSAGE: '/message',
  NOTIFICATION: '/notification',
  POPCONFIRM: '/Popconfirm',
  SPIN: '/spin',
  SHUFFLE: '/shuffle',
  AFFIX: '/affix',
  BREADCRUMB: '/breadcrumb',
  BACK_TO_TOP: '/backToTop',
  DROPDOWN: '/dropdown',
  OP_BADGE: '/op_badge',
  OP_CARD: '/op_card',
  OP_CAROUSEL: '/op_carousel',
  OP_COLLAPSE: '/op_collapse',
  OP_TOOLTIP: '/op_tooltip',
  RATING: '/rating',
  TREE: '/tree',
  OP_TAG: '/op_tag',
  OP_TIMELINE: '/op_timeline',
  OP_POPOVER: '/op_popover',
  GOOGLE_CHART: '/googleChart',
  RECHARTS: '/recharts',
  MENU: '/menu',
  REACT_CHART_2: '/ReactChart2',
  PAGINATION: '/pagination',
  CARD: '/card',
  CART: '/cart',
  CHECKOUT: '/checkout',
  SHOP: '/shop',
  REACT_DATES: '/reactDates',
  CODE_MIRROR: '/codeMirror',
  UPPY: '/uppy',
  DROPZONE: '/dropzone',
  YOUTUBE_SEARCH: '/youtubeSearch',
  FRAPPE_CHART: '/frappeChart',
  INVOICE: '/invoice',
  CHAT: '/chat',
  PROFILE: '/my-profile',
  SWIPER_SLIDER: '/swiperslider',
};
