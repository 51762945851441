import axios from 'axios';
import config from '../../../config/api.config';

export async function asyncGetArtifactDetails(id) {
  try {
    const response = await axios.get(
      `${config.BASE_URL}/${config.ARTIFACT_GET}${id}`,
    );
    return response.data.data;
  } catch (e) {
    throw new Error(e);
  }
}

export async function asyncGetProjectDetails(project_id) {
  try {
    const result = await axios.get(
      `${config.BASE_URL}/${config.PROJECT_GET}/${project_id}`,
    );
    return result.data.data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function asyncGetRestrictedAssets(
  artifactContractAddress,
  artifactTokenId,
) {
  try {
    const params = {
      artifactContractAddress: artifactContractAddress,
      artifactTokenId,
    };
    return await axios.get(
      `${config.BASE_URL}/${config.ASSET_GET_RESTRICTED}`,
      {
        params,
      },
    );
  } catch (e) {
    throw new Error(e);
  }
}

export async function asyncGetPublicAssets(
  artifactContractAddress,
  artifactTokenId,
) {
  try {
    const params = {
      artifactContractAddress: artifactContractAddress,
      artifactTokenId,
    };
    return await axios.get(`${config.BASE_URL}/${config.ASSET_GET_PUBLIC}`, {
      params,
    });
  } catch (e) {
    throw new Error(e);
  }
}

export async function asyncGetAllArtifacts() {
  return await axios.get(`${config.BASE_URL}/${config.ARTIFACT_GET_COUNT}`, {
    headers: {
      Authorization: `Token ${sessionStorage.getItem('authToken')}`,
    },
  });
}
