function filterProjectsByDomain(projects) {
  if (typeof window !== 'undefined') {
    const host = window && window?.location?.hostname;
    console.info({host});
    const splitHost = host.split('.');
    const filteredProjects = projects.filter(project => {
      const domain = project?.domain;
      if (domain) {
        // console.info({domain, host})
        const splitDomain = domain.split('.');
        if (
          splitDomain.length === splitHost.length &&
          splitDomain[0] === splitHost[0]
        ) {
          return true;
        }
        if (
          (splitDomain.length === 3 && splitHost.length === 3) ||
          (host === 'localhost' && splitDomain.length === 3)
        ) {
          // eg: app-create.fandefi.com app-dev.fandefi.com app.fandefi.com etc...
          return true;
        }

        return false;
      }
      return false;
    });
    return filteredProjects;
  }
  return undefined;
}

function getInfoFromUrl(url) {
  const splitHttp = url.split('//');
  console.info({splitHttp});

  const isHttp = !splitHttp[0].includes('s');

  const splitDomain = splitHttp[1].split(':');
  const port =
    splitDomain.length > 1 ? splitDomain[splitDomain.length - 1] : undefined;
  const domain = splitDomain[0];

  return {port, isHttp, domain};
}

function getSubDomain() {
  // app-dev.fandefi.com
  // app-create.fandefi.com
  // app-testnet.fandefi.com
  // create-testnet.fandefi.com
  // app.fandefi.com
  // create.fandefi.com
  // subdomains: ar & iam

  if (typeof window !== 'undefined') {
    const {hostname} = window.location;
    const hostnameSplit = hostname.split('.');

    // TODO: make this generic
    if (hostnameSplit.length > 0) {
      if (hostnameSplit[0] === 'iam') {
        return 'iam';
      }
      if (hostnameSplit[0] === 'ar') {
        return 'ar';
      }
    }
  }
  return undefined;
}

export {filterProjectsByDomain, getInfoFromUrl, getSubDomain};
