import React, {lazy, Suspense, useEffect} from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
  useHistory,
} from 'react-router-dom';
import {useSelector} from 'react-redux';

import ErrorBoundary from './ErrorBoundary';
import {PUBLIC_ROUTE} from './route.constants';
import Loader from '@iso/components/utility/loader';
import history from '@iso/lib/helpers/history';

import {PRIVATE_ROUTE} from './route.constants';
import {PROJECT_ROUTE} from './route.constants';

import Dashboard from './containers/Dashboard/Dashboard';
import ProjectLanding from './containers/ProjectDetails/project_landing';
import StorefrontLanding from './containers/MarketPlace/storefront_landing';
import ArtifactLanding from './containers/Artifact/artifact_landing';
import ArtifactDetails from './containers/Artifact/artifact_details';
import CustomSwitch from './customSwitch';

const publicRoutes = [
  {
    path: '/notFound',
    component: lazy(() => import('@iso/containers/public/NotFound')),
  },
  {
    path: PUBLIC_ROUTE.FANFINGERS,
    component: lazy(() => import('@iso/containers/Fanfingers/fanfingers')),
  },
  {
    path: PUBLIC_ROUTE.FANFINGERS_LANDING,
    component: lazy(() => import('@iso/containers/Fanfingers/fanfingers')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@iso/containers/Onboard/signup')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import('@iso/containers/Onboard/signup')),
  },
  {
    path: PUBLIC_ROUTE.FORGOT_PASSWORD,
    component: lazy(() => import('@iso/containers/Onboard/signup')),
  },
  {
    path: PUBLIC_ROUTE.FORGOT_PASSWORD_EMAIL_SENT,
    component: lazy(() => import('@iso/containers/Onboard/signup')),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() => import('@iso/containers/Onboard/signup')),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD_COMPLETE,
    component: lazy(() => import('@iso/containers/Onboard/signup')),
  },
  {
    path: PUBLIC_ROUTE.LANDING,
    component: lazy(() => import('@iso/containers/Onboard/signup')),
  },
  {
    path: PUBLIC_ROUTE.ARTIFACT_DETAILS,
    component: lazy(() => import('@iso/containers/Artifact/artifact_details')),
  },
  {
    path: PUBLIC_ROUTE.NEXT_BRIDGE,
    component: lazy(() => import('@iso/containers/Onboard/signin')),
  },
];

const ProjectRoute = ({children, ...rest}) => {
  return <Route {...rest} render={({location}) => children} />;
};

const StorefrontRoute = ({children, ...rest}) => {
  return <Route {...rest} render={({location}) => children} />;
};

const ArtifactRoute = ({children, ...rest}) => {
  return <Route {...rest} render={({location}) => children} />;
};

const PrivateRoute = ({children, ...rest}) => {
  const isLoggedIn = sessionStorage.getItem('authToken');
  return (
    <Route
      {...rest}
      render={({location}) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: {from: location},
            }}
          />
        )
      }
    />
  );
};
export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router history={history}>
          <CustomSwitch>
            <PrivateRoute path={PRIVATE_ROUTE.DASHBOARD}>
              <Dashboard />
            </PrivateRoute>

            <StorefrontRoute path={PROJECT_ROUTE.STOREFRONT}>
              <StorefrontLanding />
            </StorefrontRoute>

            <ProjectRoute path={PROJECT_ROUTE.PROJECT}>
              <ProjectLanding />
            </ProjectRoute>

            <ArtifactRoute path={PROJECT_ROUTE.ARTIFACT}>
              <ArtifactLanding />
            </ArtifactRoute>

            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component history={history} />
              </Route>
            ))}
          </CustomSwitch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
