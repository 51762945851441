import {getInfoFromUrl} from './utils';

export default function getNextUrl(path) {
  const currentUrl = window.location.hostname;

  const {domain: baseDomain} = getInfoFromUrl(process.env.REACT_APP_URL);
  const subDomain =
    currentUrl === 'localhost' ? undefined : currentUrl.replace(baseDomain, ''); // .replace('.', '')

  const {
    domain: nextBaseDomain,
    isHttp: nextIsHttp,
    port: nextPort,
  } = getInfoFromUrl(process.env.REACT_APP_NEXT_BASE_URL);
  const appUrl = `${nextIsHttp ? 'http' : 'https'}://${
    subDomain ? subDomain : ''
  }${nextBaseDomain}${nextPort ? `:${nextPort}` : ''}${path}`;
  console.info({
    currentUrl,
    baseDomain,
    subDomain,
    nextBaseDomain,
    nextIsHttp,
    nextPort,
    appUrl,
  });

  return appUrl;
}
