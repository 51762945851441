export const actions = {
  SET_SELECTED_PROJECT: 'SET_SELECTED_PROJECT',
  SET_SELECTED_ARTIFACT: 'SET_SELECTED_ARTIFACT',
  SET_PROJECT_ID: 'SET_PROJECT_ID',
  SET_PROJECT_TYPE: 'SET_PROJECT_TYPE',
  SET_PROJECT_NAME: 'SET_PROJECT_NAME',
  SET_PROJECT_DESC: 'SET_PROJECT_DESC',
  SET_PROJECT_CATEGORY_TYPE: 'SET_PROJECT_CATEGORY_TYPE',
  SET_PROJECT_FUNDS_USD: 'SET_PROJECT_FUNDS_USD',
  SET_PROJECT_CURRENCY: 'SET_PROJECT_CURRENCY',
  SET_PROJECT_TEAM_ROYALTY_PERCENTAGE: 'SET_PROJECT_TEAM_ROYALTY_PERCENTAGE',
  SET_PROJECT_BACKERS_ROYALTY_PERCENTAGE:
    'SET_PROJECT_BACKERS_ROYALTY_PERCENTAGE',
  SET_PROJECT_NUM_BACKER_TOKENS: 'SET_PROJECT_NUM_BACKER_TOKENS',
  SET_PROJECT_COLLABORATORS: 'SET_PROJECT_COLLABORATORS',
  SET_PROJECT_BACKER_TOKEN: 'SET_PROJECT_BACKER_TOKEN',
  SET_PROJECT_TRANSACTION_HASH: 'SET_PROJECT_TRANSACTION_HASH',
  SET_USDC_CONTRACT: 'SET_USDC_CONTRACT',
  SET_WETH_CONTRACT: 'SET_WETH_CONTRACT',
  CLEAR_ALL: 'CLEAR_ALL',

  setUsdcContract: usdcContract => {
    return {
      type: actions.SET_USDC_CONTRACT,
      usdcContract,
    };
  },

  setWethContract: wethContract => {
    return {
      type: actions.SET_WETH_CONTRACT,
      wethContract,
    };
  },

  setSelectedArtifact: selectedArtifact => {
    return {
      type: actions.SET_SELECTED_ARTIFACT,
      selectedArtifact,
    };
  },

  setSelectedProject: selectedProject => {
    return {
      type: actions.SET_SELECTED_PROJECT,
      selectedProject,
    };
  },

  setProjectType: projectType => {
    return {
      type: actions.SET_PROJECT_TYPE,
      projectType,
    };
  },
  setProjectId: projectId => {
    return {
      type: actions.SET_PROJECT_ID,
      projectId,
    };
  },
  setProjectName: projectName => {
    return {
      type: actions.SET_PROJECT_NAME,
      projectName,
    };
  },
  setProjectDesc: projectDesc => {
    return {
      type: actions.SET_PROJECT_DESC,
      projectDesc,
    };
  },
  setProjectCategoryType: projectCategoryType => {
    return {
      type: actions.SET_PROJECT_CATEGORY_TYPE,
      projectCategoryType,
    };
  },
  setProjectFunds: projectFunds => {
    return {
      type: actions.SET_PROJECT_FUNDS_USD,
      projectFunds,
    };
  },
  setProjectCurrency: projectCurrency => {
    return {
      type: actions.SET_PROJECT_CURRENCY,
      projectCurrency,
    };
  },
  setProjectTeamRoyaltyPercentage: projectTeamRoyaltyPercentage => {
    return {
      type: actions.SET_PROJECT_TEAM_ROYALTY_PERCENTAGE,
      projectTeamRoyaltyPercentage,
    };
  },
  setProjectBackersRoyaltyPercentage: projectBackersRoyaltyPercentage => {
    return {
      type: actions.SET_PROJECT_BACKERS_ROYALTY_PERCENTAGE,
      projectBackersRoyaltyPercentage,
    };
  },
  setProjectNumBackerTokens: projectNumBackerTokens => {
    return {
      type: actions.SET_PROJECT_NUM_BACKER_TOKENS,
      projectNumBackerTokens,
    };
  },
  setProjectCollaborators: projectCollaborators => {
    return {
      type: actions.SET_PROJECT_COLLABORATORS,
      projectCollaborators,
    };
  },
  setProjectBackerToken: projectBackerToken => {
    return {
      type: actions.SET_PROJECT_BACKER_TOKEN,
      projectBackerToken,
    };
  },
  setProjectTransactionHash: projectTransactionHash => {
    return {
      type: actions.SET_PROJECT_TRANSACTION_HASH,
      projectTransactionHash,
    };
  },
  clearAll: () => {
    return {
      type: actions.CLEAR_ALL,
    };
  },
};
