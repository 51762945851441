import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
// import Ecommerce from '@iso/redux/ecommerce/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import OnboardingReducer from '@iso/redux/Onboarding/reducer';
import ProjectsReducer from '@iso/redux/Projects/reducer';
import Web3Reducer from '@iso/redux/web3/reducer';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  // Ecommerce,
  LanguageSwitcher,
  OnboardingReducer,
  ProjectsReducer,
  Web3Reducer
});
