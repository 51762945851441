import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Layout} from 'antd';
import appActions from '@iso/redux/app/actions';
import TopbarWrapper from './Topbar.styles';
import {Link} from 'react-router-dom';
import IntlMessages from '../../components/utility/intlMessages';
import metamaskIcon from '../assets/images/metamask.png';
import Popover from '../../components/uielements/popover';
import PopoverWrapper from '../../containers/Uielements/Popover/Popover.styles';
import WalletAddress from '../../components/walletAddress';
import Web3 from 'web3';
import Button from '../../components/uielements/button';
import {isMobile} from 'react-device-detect';

const {Header} = Layout;
const {toggleCollapsed} = appActions;

export default function Topbar(props) {
  const [selectedItem, setSelectedItem] = React.useState('');
  const customizedTheme = useSelector(state => state.ThemeSwitcher.topbarTheme);
  const {collapsed, openDrawer} = useSelector(state => state.App);
  const dispatch = useDispatch();
  const handleToggle = React.useCallback(
    () => dispatch(toggleCollapsed()),
    [dispatch],
  );
  const isCollapsed = collapsed && !openDrawer;
  const styling = {
    background: customizedTheme.backgroundColor,
    position: 'fixed',
    width: '100%',
    height: 70,
  };
  const textStyle = {
    fontWeight: 'bold',
    fontSize: '16px',
  };

  const url = props.url;
  const userToken = sessionStorage.getItem('authToken');

  const [wrongNetwork, setWrongNetwork] = React.useState(false);
  const [isOnRightNetwork, setIsOnRightNetwork] = React.useState(false);
  const [isWalletConnected, setIsWalletConnected] = React.useState(false);
  const [walletAddress, setWalletAddress] = React.useState('');
  const defaultPopoverText = 'Copy';
  const copiedPopoverText = 'Copied';
  const [isCopied, setIsCopied] = React.useState(false);
  const [showMetaMaskPopup, setShowMetaMaskPopup] = React.useState(false);
  const [showWarningOnMobileView, setShowWarningOnMobileView] = React.useState(false);

  useEffect(() => {
    checkWalletStatus();
  }, []);

  const checkWalletStatus = async () => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      const accounts = await web3.eth.getAccounts();
      if (accounts[0]) {
        setIsWalletConnected(true);
        setWalletAddress(accounts[0]);
      }

      window.ethereum.on('accountsChanged', newAccounts => {
        const web3 = new Web3(window.ethereum);
        web3.eth.getAccounts().then(accounts => {
          if (accounts[0]) {
            setIsWalletConnected(true);
            setWalletAddress(accounts[0]);
          }
        });
      });

      if (accounts[0]) {
        await checkNetwork();
      }

      window.ethereum.on('chainChanged', async newChainId => {
        if (!document.hidden) {
          await checkNetwork();
        }
      });

      document.addEventListener('visibilitychange', async () => {
        if (!document.hidden) {
          await checkNetwork();
        }
      });
    }
  };

  const connectWallet = async () => {
    if (isMobile) {
      setShowWarningOnMobileView(true);
      return;
    } 

    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        await checkNetwork();
      } else {
        setShowMetaMaskPopup(true);
      }
    } catch (err) {
      
    }
  };

  const checkNetwork = async () => {
    const web3 = new Web3(window.ethereum);
    const networkId = await web3.eth.net.getId();
    if (networkId != process.env.REACT_APP_BLOCKCHAIN_ID) {
      setWrongNetwork(true);
      setIsOnRightNetwork(false);
    } else {
      setWrongNetwork(false);
      setIsOnRightNetwork(true);
    }
  };

  const changeNetwork = async () => {
    closePopup();

    const web3 = new Web3(window.ethereum);
    const networkId = await web3.eth.net.getId();

    if (networkId !== process.env.REACT_APP_BLOCKCHAIN_ID) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: process.env.REACT_APP_BLOCKCHAIN_ID_HEX }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            const network = {
              chainId: process.env.REACT_APP_BLOCKCHAIN_ID_HEX,
              chainName: process.env.REACT_APP_BLOCKCHAIN_NAME,
              nativeCurrency: {
                name: 'Matic',
                symbol: 'MATIC',
                decimals: 18,
              },
              rpcUrls: [process.env.REACT_APP_BLOCKCHAIN_URL],
              blockExplorerUrls: [
                process.env.REACT_APP_BLOCKCHAIN_EXPLORER_URL,
              ],
            };
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [network],
            });
          } catch (addError) {
            
          }
        }
      }
    }
  };

  const closePopup = e => {
    setShowMetaMaskPopup(false);
    setWrongNetwork(false);
    setShowWarningOnMobileView(false);
  };

  const content = (
    <PopoverWrapper>
      <p>{isCopied ? copiedPopoverText : defaultPopoverText}</p>
    </PopoverWrapper>
  );

  const copyWalletAddress = walletAddress => {
    navigator.clipboard.writeText(walletAddress);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <TopbarWrapper>
      <Header
        style={styling}
        className={
          props.state === 'private'
            ? isCollapsed
              ? 'isomorphicTopbar collapsed'
              : 'isomorphicTopbar'
            : 'isomorphicTopbar noSpacing'
        }
      >
        <div className="isoLeft">
          {props.state === 'public' ? (
            <div>
              <img
                src="/images/FandefiLogo-Lagest-01.svg"
                height="50px"
                width="120px"
              />
            </div>
          ) : (
            <button
              className={
                isCollapsed ? 'triggerBtn menuCollapsed' : 'triggerBtn menuOpen'
              }
              style={{color: customizedTheme.textColor}}
              onClick={handleToggle}
            />
          )}
        </div>

        <ul className="isoRight">
          {isWalletConnected && isOnRightNetwork ? (
            <div className="metamask">
              <div
                style={{
                  display: 'flex',
                  margin: '5px 0px',
                  alignItems: 'center',
                }}
              >
                <img
                  alt="MetaMask"
                  src={metamaskIcon}
                  style={{width: '33px', height: '33px'}}
                />
                <Popover content={content} title="" trigger="hover">
                  <div onClick={() => copyWalletAddress(walletAddress)}>
                    <WalletAddress
                      walletAddress={walletAddress}
                      walletStyle="topbarWalletAddress"
                    />
                  </div>
                </Popover>
              </div>
            </div>
          ) : (
            <div
              className="metamask"
              style={{cursor: 'pointer', paddingLeft: '0px'}}
              onClick={() => connectWallet()}
            >
              <div
                style={{
                  display: 'flex',
                  margin: '5px 0px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '33px',
                  textAlign: 'center',
                }}
              >
                <span
                  style={{
                    color: '#FFFFFF',
                    font: 'normal normal bold 12px/16px Roboto',
                  }}
                >
                  CONNECT WALLET
                </span>
              </div>
            </div>
          )}

          {/* <li>
            <div style={{border: '1px solid #E2E2E2', height: '50px'}}></div>
          </li> */}
          {/* <li className={userToken ? 'hidden' : ''}>
            <Link className="plainLink" to="/signin">
              Login
            </Link>
          </li>
          <li className={userToken ? 'hidden' : 'fill'}>
            <Link className="border" to="/signup">
              <IntlMessages id="Onboard.signup" />
            </Link>
          </li> */}
          {/* <li className={!userToken ? 'hidden' : ''}>
            <Link className="plainLink" to="/dashboard/myaccount">
              My Account
            </Link>
          </li> */}
        </ul>
      </Header>

      {showMetaMaskPopup && <div className="modal_mask"></div>}
      {showMetaMaskPopup && (
        <div style={{ position: 'fixed', width: '100%', height: '94%', zIndex: '999999', left: '0px', top: '40%' }}>
          <div className="addPopup" style={{ position: 'initial', paddingBottom: '13px', paddingTop: '17px', maxHeight: '100%' }}>
            <div className="popup_header" style={{ position: 'relative' }}>
              <h4 style={{ paddingRight: '15px', fontWeight: 'bold', fontSize: '18px' }}>
                Get MetaMask
              </h4>
              <span style={{ fontWeight: 'bold', top: '0' }} onClick={() => closePopup()}></span>
            </div>
            <div className="main">
              <span className="inputHeader">
                MetaMask is required to access your Ethereum / Polygon wallet
              </span>
            </div>
            <div className="isoButtonWrapper">
              <a target="_blank" href="https://metamask.io/download.html" className="ant-btn" onClick={() => closePopup()}>
                Get MetaMask
              </a>
            </div>
          </div>
        </div>
      )}

      {showWarningOnMobileView && <div className="modal_mask"></div>}
      {showWarningOnMobileView && (
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '94%',
            zIndex: '999999',
            left: '0px',
            top: '40%',
          }}
        >
          <div
            className="addPopup"
            style={{
              position: 'initial',
              paddingBottom: '13px',
              paddingTop: '17px',
              maxHeight: '100%',
            }}
          >
            <div className="popup_header" style={{ position: 'relative' }}>
              <h4
                style={{
                  paddingRight: '15px',
                  fontWeight: 'bold',
                  fontSize: '18px',
                }}
              >
                You're on a mobile browser
              </h4>
              <span
                style={{ fontWeight: 'bold', top: '0' }}
                onClick={() => closePopup()}
              ></span>
            </div>
            <div className="main">
              <span className="inputHeader">
                Oops, looks like you're on a mobile browser. We recommend using Chrome on desktop.
              </span>
            </div>
          </div>
        </div>
      )}

      {wrongNetwork && <div className="modal_mask"></div>}
      {wrongNetwork && (
        <div style={{ position: 'fixed', width: '100%', height: '94%', zIndex: '999999', left: '0px', top: '40%' }}>
          <div className="addPopup" style={{ position: 'initial', paddingBottom: '13px', paddingTop: '17px', maxHeight: '100%' }}>
            <div className="popup_header" style={{ position: 'relative' }}>
              <h4 style={{ paddingRight: '15px', fontWeight: 'bold', fontSize: '18px' }}>
                Wrong network
              </h4>
              <span style={{ fontWeight: 'bold', top: '0' }} onClick={() => closePopup()}></span>
            </div>
            <div className="main">
              <span className="inputHeader">

              </span>
            </div>
            <div className="isoButtonWrapper">
              <Button type="primary" onClick={() => changeNetwork()}>
                Switch to {process.env.REACT_APP_BLOCKCHAIN_NAME}
              </Button>
            </div>
          </div>
        </div>
      )}
    </TopbarWrapper>
  );
}
