import React from 'react';

import {Button} from 'antd';
import {ethers, utils, BigNumber} from 'ethers';
import {useArtifactDetails} from '../contexts/artifact-details-context';
import Modal from '../components/modal';

import {
  asyncCheckBrowser,
  asyncChangeNetwork,
  redirectToMetamask,
  getFandefiContracts,
  getCurrencyContracts,
} from '../utils/web3Utils';
import {
  asyncGetArtifactDetails,
  asyncGetPublicAssets,
  asyncGetProjectDetails,
  asyncGetRestrictedAssets,
} from '../utils/api';
import {getContractAddress} from 'ethers/lib/utils';

require('dotenv').config({
  path: `../.env.${process.env.NODE_ENV}`,
});

const USDC = 1;
const MATIC = 2;
const WETH = 3;

function ArtifactBuyButton() {
  const [data, setData] = useArtifactDetails();

  React.useEffect(() => {
    (async () => {
      try {
        const artifact = await asyncGetArtifactDetails(data.artifact.id);
        const project = await asyncGetProjectDetails(artifact.project_id);
        const contentUnsorted = (
          await asyncGetPublicAssets(
            artifact.artifactTokenContract_address,
            artifact.artifactTokenId,
          )
        ).data.data;
        const assets = {};
        contentUnsorted.forEach((item, index) => {
          assets[item.assetType] = item;
        });
        artifact['assets'] = assets;
        console.info({artifact});
        console.info({project});

        setData({
          artifact: artifact,
          project: project,
          flags: {
            saleApproved: true,
            loading: false,
            wasCreatedByCurrencContract: true,
          },
        });
      } catch (error) {
        handleError(error);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (data.artifact._id !== undefined) {
      (async () => {
        console.info('getting contract data from blockchain');
        asyncGetLiveArtifactData(false);
      })();
    }
  }, [data.flags.loading]);

  React.useEffect(() => {
    // run when network changes
    if (window.ethereum) {
      window.ethereum.on('chainChanged', async chainId => {
        (async () => {
          try {
            const buyClicked = false;
            await asyncCheckBrowser();
            if (data.artifact._id != undefined) {
              await asyncGetLiveArtifactData(buyClicked);
            }
          } catch (error) {
            console.error(error);
          }
        })();
        //setChangeNetwork(true);
      });
    }
  });

  const [showModal, setShowModal] = React.useState();
  const [showErrorModal, setShowErrorModal] = React.useState();
  const [modalTitle, setModalTitle] = React.useState();
  const [modalMessage, setModalMessage] = React.useState();
  const [showUploads, setShowUploads] = React.useState();
  const [metamaskNotInstalled, setMetamaskNotInstalled] = React.useState(false);
  const [changeNetwork, setChangeNetwork] = React.useState(false);

  const [fandefiContracts, setFandefiContracts] = React.useState();
  const [currencyContracts, setCurrencyContracts] = React.useState();
  const [userWallet, setUserWallet] = React.useState();
  const [mintComplete, setMintComplete] = React.useState(false);
  const [metaState, setMetaState] = React.useState();
  const [web3User, setWeb3User] = React.useState();

  const [value, setValue] = React.useState(2);
  const [refresh, setRefresh] = React.useState(false);

  function closeDialog() {
    setMetamaskNotInstalled(false);
    setShowErrorModal(false);
    setShowModal(false);
    setChangeNetwork(false);
  }

  async function getContractData() {}

  async function asyncGetLiveArtifactData(buyClicked) {
    try {
      const artifact = data.artifact;
      const browserState = await asyncCheckBrowser();
      console.log({browserState});
      if (browserState.flags.installMetamask && buyClicked) {
        setMetamaskNotInstalled(true);
      }
      if (browserState.flags.changeNetwork) {
        //setChangeNetwork(true);
      }

      const {tokenManagerContract, backerTokenContract, artifactContract} =
        getFandefiContracts();
      setFandefiContracts({
        tokenManagerContract,
        backerTokenContract,
        artifactContract,
      });
      const {usdcContract, wethContract} = getCurrencyContracts();
      setCurrencyContracts({usdcContract, wethContract});
      // Now get info from block chain
      console.log({data});
      console.log({artifactContract, id: artifact.artifactTokenId});
      const artifactInfo = await artifactContract.methods
        .getArtifact(artifact.artifactTokenId)
        .call()
        .catch(error => {
          console.error(error);
        });
      console.info({artifactInfo});
      //return;

      const backerTokenInfo = {
        collaborators: await backerTokenContract.methods
          .getCollaborators(artifact.backerTokenId)
          .call(),
        holders: await backerTokenContract.methods
          .getHolders(artifact.backerTokenId)
          .call(),
        collaboratorTokens: await backerTokenContract.methods
          .getCollaboratorNumTokens(artifact.backerTokenId)
          .call(),
        totalSupply: await backerTokenContract.methods
          .getTotalSupply(artifact.backerTokenId)
          .call(),
      };

      const backerTokensHeld = await backerTokenContract.methods
        .balanceOfBatch(
          backerTokenInfo.holders,
          Array(backerTokenInfo.holders.length).fill(
            artifactInfo._backerTokenId,
          ),
        )
        .call();

      const saleApproved = await artifactContract.methods
        .isApprovedForAll(
          artifactInfo._creator,
          tokenManagerContract.options.address,
        )
        .call();

      // get additional content if any;

      console.info({data});
      let dataUpdate = {
        ...data,
        artifactLiveInfo: {
          backerTokenId: artifactInfo._backerTokenId,
          creator: artifactInfo._creator,
          currency: artifactInfo._currency,
          editions: artifactInfo._editions,
          id: artifactInfo._id,
          price: artifactInfo._price,
          royalty: artifactInfo._royalty,
          royaltyType: artifactInfo._royaltyType,
          editionsSold: artifactInfo._editionsSold,
        },
        backerTokenLiveInfo: {
          collaboratorTokens: backerTokenInfo.collaboratorTokens,
          collaborators: backerTokenInfo.collaborators,
          holders: backerTokenInfo.holders,
          backerTokensHeld: backerTokensHeld,
        },
        flags: {
          ...data.flags,
          wasCreatedByCurrencContract:
            artifactContract.options.address ===
            artifact.artifactTokenContract_address,
          saleApproved: saleApproved,
        },
      };

      if (browserState.account) {
        console.log('fetching locked content');
        const walletHolderEditionsOwned = await artifactContract.methods
          .balanceOf(browserState.account, artifactInfo._id)
          .call();
        const isCreator =
          browserState.account.toLowerCase() ===
          artifactInfo._creator.toLowerCase();

        let restrictedContent;
        if (walletHolderEditionsOwned > 0 || isCreator) {
          restrictedContent = await asyncGetRestrictedAssets(
            artifact.artifactTokenContract_address,
            artifactInfo._id,
          );
          console.info({lockedContent: restrictedContent});
        }
        console.info({walletHolderEditionsOwned, isCreator});
        dataUpdate = {
          ...dataUpdate,
          artifactLiveInfo: {
            ...dataUpdate.artifactLiveInfo,
            lockedContent: restrictedContent ? restrictedContent.data.data : [],
          },
          flags: {
            ...dataUpdate.flags,
            isCreator,
            walletHolderEditionsOwned,
          },
        };
      }
      console.info({dataUpdate});
      dataUpdate = {
        ...dataUpdate,
        flags: {
          ...dataUpdate.flags,
          loading: false,
        },
      };
      setData({...dataUpdate});
      setUserWallet(browserState.account);
    } catch (error) {
      console.error(error.message);
      // setModalTitle(`Browser incompatible`);
      // setModalMessage("We currently recommend Chrome Browser with Metamask on desktop.");
      // setShowErrorModal(true);
    }
  }

  async function asyncApproveTokenManager() {
    try {
      let gasEstimate = 0;
      setModalTitle('Allow Sale of Artifact');
      setModalMessage(
        'Approve Fandefi TokenManager to administer artifact sales. Please wait for Metamask prompt.',
      );
      setShowModal(true);
      await fandefiContracts.artifactContract.methods
        .setApprovalForAll(
          fandefiContracts.tokenManagerContract.options.address,
          true,
        )
        .estimateGas({
          from: userWallet,
        })
        .then(gasAmount => {
          gasEstimate = gasAmount;
        })
        .catch(error => {});

      if (gasEstimate === 0) {
        gasEstimate = 140485;
      }

      await fandefiContracts.artifactContract.methods
        .setApprovalForAll(
          fandefiContracts.tokenManagerContract.options.address,
          true,
        )
        .send({
          from: userWallet,
          gasPrice: '99000000000',
          gas: gasEstimate * 3,
        })
        .on('transactionHash', txnHash => {
          setModalMessage('Approval in progress.');
        })
        .on('receipt', receipt => {
          // show txn completed
          setModalMessage('Approval complete.');
        })
        .on('error', (error, receipt) => {});
    } catch (e) {}
    setShowModal(false);
    setShowErrorModal(true);
  }

  async function asyncBuyArtifact() {
    const buyClicked = true;
    setModalTitle('Artifact Purchase');
    setModalMessage('Artifact purchase in progress.');
    setShowModal(true);
    if (data.artifactLiveInfo === undefined) {
      await asyncGetLiveArtifactData(buyClicked);
    }
    const editions = data.flags.editionsToBuy;
    // if (data.artifactLiveInfo === undefined) {
    //   setModalTitle('Please try again');
    //   setModalMessage('Give a moment for contract data to load.');
    //   setShowErrorModal(true);
    //   return;
    // }

    if (data.flags.isCreator) {
      setShowModal(false);
      setModalTitle('Change your account to buy.');
      setModalMessage(
        'As creator of this collection you cannot buy your own artifacts.',
      );
      setShowErrorModal(true);
      return;
    }
    if (!data.flags.wasCreatedByCurrencContract) {
      setShowModal(false);
      setModalTitle('Cannot buy this Artifact');
      setModalMessage('This Artifact is from an older development build.');
      setShowErrorModal(true);
      return;
    }

    try {
      let selectedCurrencyContract = null;

      const decimals =
        data.artifact.currency === USDC
          ? process.env.REACT_APP_USDC_DECIMALS
          : 18;
      const totalCost = BigNumber.from(
        (data.artifact.price * editions * 10 ** decimals).toString(),
      );

      let txnValue = data.artifact.currency === MATIC ? totalCost : 0;

      if (data.artifact.currency != MATIC) {
        const hasEnoughCurrency = await asyncHasEnoughCurrency(totalCost);

        if (!hasEnoughCurrency) {
          setShowModal(false);
          setModalTitle('Buy Artifact');
          setModalMessage('Insufficient Funds');
          setShowErrorModal(true);
          return;
        }
      }

      if (data.artifact.currency === USDC) {
        selectedCurrencyContract = currencyContracts.usdcContract;
      } else if (data.artifact.currency === WETH) {
        selectedCurrencyContract = currencyContracts.wethContract;
      }

      if (selectedCurrencyContract !== null) {
        const allowance = await selectedCurrencyContract.allowance(
          userWallet,
          fandefiContracts.tokenManagerContract.options.address,
        );

        if (allowance.lt(totalCost)) {
          const atxn = await selectedCurrencyContract.approve(
            fandefiContracts.tokenManagerContract.options.address,
            totalCost,
          );
          setModalMessage.apply('Approval is in progress');
          const approvalTxn = await atxn.wait();

          if (!approvalTxn.status) {
            setModalTitle('Error');
            setModalMessage('Approve transaction failed.');
            setShowModal(false);
            setShowErrorModal(true);
            return;
          } else {
            setModalMessage('Approve completed successfully.');
          }
        }
      }

      setModalTitle('Artifact Purchase');
      setModalMessage('Artifact purchase in progress.');
      setShowModal(true);

      let gasEstimate = 0;

      const gasTxn = await fandefiContracts.tokenManagerContract.methods
        .processArtifactSale(
          data.artifactLiveInfo.id,
          data.artifactLiveInfo.creator,
          editions,
        )
        .estimateGas({
          from: userWallet,
          value: txnValue,
        })
        .then(gasAmount => {
          gasEstimate = gasAmount;
        })
        .catch(error => {});

      if (gasEstimate === 0) {
        gasEstimate = 140485;
      }

      const txnData = await fandefiContracts.tokenManagerContract.methods
        .processArtifactSale(
          data.artifactLiveInfo.id,
          data.artifactLiveInfo.creator,
          editions,
        )
        .send({
          from: userWallet,
          value: txnValue,
          gasPrice: '99000000000',
          gas: gasEstimate * 3,
        })
        .on('transactionHash', txnHash => {
          setModalMessage('Buy in progress');
        })
        .on('receipt', receipt => {
          setShowModal(false);
          setModalTitle('Buy Complete.');
          setShowModal(false);
          setMintComplete(true);
        })
        .on('error', (error, receipt) => {
          setShowModal(false);
          setModalMessage('There was an error during transaction.');
          setShowErrorModal(true);
        });
    } catch (error) {
      console.error(error);
      setModalMessage(error.message);
      setShowModal(false);
      setShowErrorModal(true);
    }
  }

  async function asyncGetBalance() {
    const selectedCurrencyContract =
      data.artifact.currency === 1
        ? currencyContracts.usdcContract
        : currencyContracts.wethContract;
    const metamaskProvider = new ethers.providers.Web3Provider(window.ethereum);

    const _signer = metamaskProvider.getSigner();
    let updatedSelectedCurrencyContract =
      selectedCurrencyContract.connect(_signer);

    const balance = await updatedSelectedCurrencyContract.balanceOf(userWallet);

    return balance;
  }

  async function asyncHasEnoughCurrency(totalCost) {
    const balance = await asyncGetBalance(data);
    return balance.gt(totalCost);
  }

  function reloadWindow() {
    window.location.reload();
  }

  function handleError(error) {
    if (
      error.message ===
      "Request of type 'wallet_switchEthereumChain' already pending for origin http://localhost:3004. Please wait."
    ) {
      setModalTitle('Metamask');
      setModalMessage('Please complete the request pending in Metamask.');
      setShowErrorModal(true);
    } else {
      setModalTitle('Metamask');
      setModalMessage(error.message);
      setShowErrorModal(true);
    }
  }

  // return true for OK

  function onClickHandler() {
    (async () => {
      await asyncBuyArtifact();
    })();
  }

  function refreshPage() {
    window.location.reload(false);
  }

  if (showErrorModal) {
    return (
      <Modal
        icon="ion-android-alert"
        closeModalPopup={closeDialog}
        heading={modalTitle}
        message={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              justifyItems: 'center',
            }}
          >
            <div style={{fontSize: '18px'}}>{modalMessage}</div>
            <div style={{fontSize: '18px'}}>
              <Button
                type="primary"
                style={{
                  borderRadius: '4px',
                  margin: '10px',
                  textAlign: 'center',
                }}
                onClick={() => {
                  //asyncCheckBrowser();
                  setShowErrorModal(false);
                }}
              >
                Close
              </Button>
            </div>
          </div>
        }
      />
    );
  } else if (metamaskNotInstalled) {
    return (
      <Modal
        closeModalPopup={closeDialog}
        icon="ion-android-alert"
        heading="Install Metamask"
        message={
          <Button
            type="primary"
            style={{borderRadius: '4px', margin: '10px'}}
            onClick={redirectToMetamask}
          >
            Get Metamask
          </Button>
        }
      />
    );
  } else if (mintComplete) {
    return (
      <Modal
        closeModalPopup={closeDialog}
        icon="ion-android-alert"
        heading={modalTitle}
        message={
          <Button
            type="primary"
            style={{borderRadius: '4px', margin: '10px'}}
            onClick={reloadWindow}
          >
            Reload Page
          </Button>
        }
      />
    );
  } else if (changeNetwork) {
    return (
      <Modal
        closeModalPopup={closeDialog}
        icon="ion-android-alert"
        heading="Change Metamask Network"
        message={
          <div>
            <Button
              type="Primary"
              style={{
                borderRadius: '4px',
                margin: '10px',
                paddingRight: '20px',
              }}
              onClick={asyncChangeNetwork}
            >
              Switch to {process.env.REACT_APP_BLOCKCHAIN_NAME}
            </Button>
          </div>
        }
      />
    );
  } else if (showModal) {
    return (
      <Modal
        icon="ion-android-alert"
        heading={modalTitle}
        message={modalMessage}
      />
    );
  } else if (!data.flags.saleApproved && data.flags.isCreator) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
          }}
        >
          <Button
            onClick={asyncApproveTokenManager}
            style={{
              borderRadius: '12px',
              backgroundColor: '#00CFFF',
              alignSelf: 'center',
            }}
          >
            <span
              style={{
                fontSize: '2em',
                fontWeight: 'bold',
                color: '#FDF6EA',
              }}
            >
              Enable Sale
            </span>
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Button
          disabled={
            data.artifactLiveInfo === undefined ||
            !data.flags.saleApproved ||
            data.flags.loading
          }
          onClick={onClickHandler}
          style={{
            borderRadius: '12px',
            backgroundColor: '#00CFFF',
            width: '300px',
            height: '60px',
          }}
        >
          <span
            style={{
              fontSize: '2em',
              fontWeight: 'bold',
              color: '#FDF6EA',
            }}
          >
            {!data.flags.loading
              ? data.artifactLiveInfo === undefined && window.ethereum
                ? 'Loading'
                : 'Buy'
              : 'Buy'}
          </span>
        </Button>
      </div>
    );
  }
}

export default ArtifactBuyButton;
