const options = [
  {
    key: 'home',
    label: 'sidebar.home',
    leftIcon: 'dashboard_icon',
  },
  {
    key: 'projects',
    label: 'sidebar.projects',
    leftIcon: 'projects_icon',
  },
  {
    key: 'assets',
    label: 'sidebar.assets',
    leftIcon: 'assets_icon',
  },
  {
    key: 'landingpages',
    label: 'sidebar.landingpages',
    leftIcon: 'landing_pages_icon',
  },
  {
    key: 'funds',
    label: 'sidebar.funds',
    leftIcon: 'funds_icon',
  },
  {
    key: 'fans',
    label: 'sidebar.fans',
    leftIcon: 'fans_icon',
  },
  {
    key: 'marketplace',
    label: 'sidebar.marketplace',
    leftIcon: 'market_place_icon',
  },
];
export default options;
