import {actions} from './actions';
import Web3 from 'web3';
import {ethers, utils, BigNumber} from 'ethers';
import BackerTokenContract from '../../contracts/BackerToken.json';
import ArtifactTokenContract from '../../contracts/ArtifactToken.json';
import TokenManagerContract from '../../contracts/TokenManager.json';

const networkId = process.env.REACT_APP_BLOCKCHAIN_ID;
//const web3Provider = window.ethereum ? window.ethereum : 'https://rpc-mumbai.maticvigil.com/v1/52180d765b969504418d117eb78271f5773ff231';
const web3 = new Web3(process.env.REACT_APP_BLOCKCHAIN_URL);
/* const web3 = new Web3(web3Provider); */

const initState = {
  web3,
  web3User: null,
  web3Network: networkId,
  backerTokenContract: new web3.eth.Contract(
    BackerTokenContract.abi,
    BackerTokenContract.networks[networkId] && BackerTokenContract.networks[networkId].address,
  ),
  artifactTokenContract: new web3.eth.Contract(
    ArtifactTokenContract.abi,
    ArtifactTokenContract.networks[networkId] && ArtifactTokenContract.networks[networkId].address,
  ),
  tokenManagerContract: new web3.eth.Contract(
    TokenManagerContract.abi,
    TokenManagerContract.networks[networkId] && TokenManagerContract.networks[networkId].address,
  ),
  usdcContract: new ethers.Contract(
    process.env.REACT_APP_USDC_CONTRACT_ADDRESS,
    [
      'function approve(address spender, uint256 amount) public virtual override returns (bool)',
      'function allowance(address owner, address spender) public view virtual override returns (uint256)',
      'function balanceOf(address account) public view virtual override returns (uint256)'
    ]
  ),
  wethContract: new ethers.Contract(
    process.env.REACT_APP_WETH_CONTRACT_ADDRESS,
    [
      'function approve(address spender, uint256 amount) public virtual override returns (bool)',
      'function allowance(address owner, address spender) public view virtual override returns (uint256)',
      'function balanceOf(address account) public view virtual override returns (uint256)'
    ]
  ),
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_WEB3_PROVIDER:
      state.web3.setProvider(action.web3Provider);
      let metamaskProvider = new ethers.providers.Web3Provider(
        action.web3Provider
      );
      let _signer = metamaskProvider.getSigner();
      let usdcContract = state.usdcContract.connect(_signer);
      let wethContract = state.wethContract.connect(_signer);
      return {
        ...state,
        usdcContract,
        wethContract
      };
    case actions.SET_WEB3_USER:
      let metamaskProvider2 = new ethers.providers.Web3Provider(
        window.ethereum
      );
      let _signer2 = metamaskProvider2.getSigner();
      let usdcContract2 = state.usdcContract.connect(_signer2);
      let wethContract2 = state.wethContract.connect(_signer2);
      return {
        ...state,
        web3User: action.web3User,
        usdcContract2,
        wethContract2
      };
    case actions.SET_WEB3_NETWORK:
      return state;
    default:
      return state;
  }
};

export default reducer;
