import Web3 from 'web3';
import {ethers, utils, BigNumber} from 'ethers';
// import ArtifactTokenContract from '../../../contracts/ArtifactToken.json';
/* import ArtifactTokenContract from '../../../contracts/ArtifactToken.json';
import TokenManagerContract from '../../../contracts/TokenManager.json';
import BackerTokenContract from '../../../contracts/BackerToken.json'; */
import ArtifactTokenV1Contract from '../../../contracts/ArtifactToken.json'
import TokenManagerV1Contract from '../../../contracts/TokenManager.json'
import TokenManagerV2Contract from '../../../contracts/TokenManagerV2.json'
import BackerTokenV1Contract from '../../../contracts/BackerToken.json'
import ArtifactTokenV2Contract from '../../../contracts/ArtifactTokenV2.json'
import TokenManagerV3Contract from '../../../contracts/TokenManagerV3.json'
import TokenTransferManagerV1Contract from '../../../contracts/TokenTransferManagerV1.json'
import BackerTokenV2Contract from '../../../contracts/BackerTokenV2.json'
import axios from 'axios';
import convert from 'ether-converter';

export async function asyncGasEstimate() {
  const POLYGONSCAN_API_KEY = 'R7S67VISM5KI6ZY5YPGUJMU3ZSUK5S43NP';
  const url = `https://api.polygonscan.com/api?module=gastracker&action=gasoracle&apikey=${POLYGONSCAN_API_KEY}`;
  return axios
    .get(url)
    .then(response => {
      console.info({gasEstimate: response.data.result});
      const gasPrice = convert(
        response.data.result.ProposeGasPrice,
        'gwei',
        'wei',
      );
      return gasPrice;
    })
    .catch(error => {
      console.error({gasEstimatorError: error});
      throw new Error(error);
    });
}

export async function asyncCheckBrowser() {
  try {
    const result = {
      flags: {
        installMetamask: false,
        changeNetwork: false,
      },
      account: undefined,
    };
    console.log('checking browser');
    if (window.ethereum && !window.hidden) {
      console.info(`wallet installed`);
      result.flags.installMetamask = false;
      const web3 = new Web3(window.ethereum);
      const accounts = await web3.eth.getAccounts();
      result.account = accounts[0];
      await window.ethereum.enable();
      await web3.eth.net
        .getId()
        .then(networkId => {
          if (networkId !== parseInt(process.env.REACT_APP_BLOCKCHAIN_ID)) {
            result.flags.changeNetwork = true;
          } else {
            result.flags.changeNetwork = false;
          }
        })
        .catch(error => {
          throw new Error(error);
        });
    } else {
      result.flags.installMetamask = true;
    }
    return result;
  } catch (error) {
    throw new Error(error);
  }
}

export async function asyncChangeNetwork() {
  const web3 = new Web3(window.ethereum);
  const networkId = await web3.eth.net.getId();
  if (parseInt(process.env.REACT_APP_BLOCKCHAIN_ID) !== networkId) {
    try {
      window.ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [{chainId: process.env.REACT_APP_BLOCKCHAIN_ID_HEX}],
        })
        .catch(error => {
          throw new Error(error);
        });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          const network = {
            chainId: process.env.REACT_APP_BLOCKCHAIN_ID_HEX,
            chainName: process.env.REACT_APP_BLOCKCHAIN_NAME,
            nativeCurrency: {
              name: 'Matic',
              symbol: 'MATIC', // 2-6 characters long
              decimals: 18,
            },
            rpcUrls: [process.env.REACT_APP_BLOCKCHAIN_URL],
            blockExplorerUrls: [process.env.REACT_APP_BLOCKCHAIN_EXPLORER_URL],
            //iconUrls?: string[]; // Currently ignored.
          };
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [network],
          });
        } catch (error) {
          throw new Error(error);
        }
      } else {
        throw new Error(switchError);
      }
    }
  }
}

export async function asyncGetWalletAddress() {
  const web3 = new Web3(window.ethereum);
  const accounts = await web3.eth.getAccounts();
  return accounts[0];
}

export function redirectToMetamask() {
  window.open('https://metamask.io/download.html', '_blank');
}

export function getFandefiContracts(version = 'v1') {
  try {
    const web3 = new Web3(window.ethereum);
    const tokenTransferManagerContract = new web3.eth.Contract(
      TokenTransferManagerV1Contract.abi,
      TokenTransferManagerV1Contract.networks[process.env.REACT_APP_BLOCKCHAIN_ID] &&
        TokenTransferManagerV1Contract.networks[process.env.REACT_APP_BLOCKCHAIN_ID].address
    )
    const tokenManagerV2Contract = new web3.eth.Contract(
      TokenManagerV2Contract.abi,
      TokenManagerV2Contract.networks[process.env.REACT_APP_BLOCKCHAIN_ID] &&
        TokenManagerV2Contract.networks[process.env.REACT_APP_BLOCKCHAIN_ID].address
    )
    let artifactContract
    let tokenManagerContract
    let backerTokenContract
    if (version === 'v1') {
      artifactContract = new web3.eth.Contract(
        ArtifactTokenV1Contract.abi,
        ArtifactTokenV1Contract.networks[process.env.REACT_APP_BLOCKCHAIN_ID] &&
          ArtifactTokenV1Contract.networks[process.env.REACT_APP_BLOCKCHAIN_ID].address
      )
      tokenManagerContract = new web3.eth.Contract(
        TokenManagerV1Contract.abi,
        TokenManagerV1Contract.networks[process.env.REACT_APP_BLOCKCHAIN_ID] &&
          TokenManagerV1Contract.networks[process.env.REACT_APP_BLOCKCHAIN_ID].address
      )
      backerTokenContract = new web3.eth.Contract(
        BackerTokenV1Contract.abi,
        BackerTokenV1Contract.networks[process.env.REACT_APP_BLOCKCHAIN_ID] &&
          BackerTokenV1Contract.networks[process.env.REACT_APP_BLOCKCHAIN_ID].address
      )
    } else if (version === 'v2') {
      artifactContract = new web3.eth.Contract(
        ArtifactTokenV2Contract.abi,
        ArtifactTokenV2Contract.networks[process.env.REACT_APP_BLOCKCHAIN_ID] &&
          ArtifactTokenV2Contract.networks[process.env.REACT_APP_BLOCKCHAIN_ID].address
      )
      tokenManagerContract = new web3.eth.Contract(
        TokenManagerV3Contract.abi,
        TokenManagerV3Contract.networks[process.env.REACT_APP_BLOCKCHAIN_ID] &&
          TokenManagerV3Contract.networks[process.env.REACT_APP_BLOCKCHAIN_ID].address
      )
      backerTokenContract = new web3.eth.Contract(
        BackerTokenV2Contract.abi,
        BackerTokenV2Contract.networks[process.env.REACT_APP_BLOCKCHAIN_ID] &&
          BackerTokenV2Contract.networks[process.env.REACT_APP_BLOCKCHAIN_ID].address
      )
    }
    /* const artifactContract = new web3.eth.Contract(
      ArtifactTokenContract.abi,
      ArtifactTokenContract.networks[process.env.REACT_APP_BLOCKCHAIN_ID] &&
        ArtifactTokenContract.networks[process.env.REACT_APP_BLOCKCHAIN_ID]
          .address,
    );
    const tokenManagerContract = new web3.eth.Contract(
      TokenManagerContract.abi,
      TokenManagerContract.networks[process.env.REACT_APP_BLOCKCHAIN_ID] &&
        TokenManagerContract.networks[process.env.REACT_APP_BLOCKCHAIN_ID]
          .address,
    );
    const backerTokenContract = new web3.eth.Contract(
      BackerTokenContract.abi,
      BackerTokenContract.networks[process.env.REACT_APP_BLOCKCHAIN_ID] &&
        BackerTokenContract.networks[process.env.REACT_APP_BLOCKCHAIN_ID]
          .address,
    ); */

    return {
      tokenManagerContract,
      tokenManagerV2Contract,
      tokenTransferManagerContract,
      backerTokenContract,
      artifactContract,
    };
  } catch (error) {
    throw new Error(error);
  }
}

export function getCurrencyContracts() {
  try {
    let usdcContract = new ethers.Contract(
      process.env.REACT_APP_USDC_CONTRACT_ADDRESS,
      [
        'function approve(address spender, uint256 amount) public virtual override returns (bool)',
        'function allowance(address owner, address spender) public view virtual override returns (uint256)',
        'function balanceOf(address account) public view virtual override returns (uint256)',
      ],
    );
    let wethContract = new ethers.Contract(
      process.env.REACT_APP_WETH_CONTRACT_ADDRESS,
      [
        'function approve(address spender, uint256 amount) public virtual override returns (bool)',
        'function allowance(address owner, address spender) public view virtual override returns (uint256)',
        'function balanceOf(address account) public view virtual override returns (uint256)',
      ],
    );

    const metamaskProvider = new ethers.providers.Web3Provider(window.ethereum);

    const signer = metamaskProvider.getSigner();
    usdcContract = usdcContract.connect(signer);
    wethContract = wethContract.connect(signer);
    return {usdcContract, wethContract, metamaskProvider, signer};
  } catch (error) {
    throw new Error(error);
  }
}
