function formatNumber(value, decimalPlaces = 2) {
  if (value === '') {
    return 0;
  } else {
    const formatted = Number(
      Math.round(parseFloat(value + 'e' + decimalPlaces)) +
        'e-' +
        decimalPlaces,
    );
    return +formatted;
  }
}

function isInteger(value) {
  if (!Number.MAX_SAFE_INTEGER) {
    Number.MAX_SAFE_INTEGER = 9007199254740991; // Math.pow(2, 53) - 1;
  }
  Number.isSafeInteger =
    Number.isSafeInteger ||
    function (value) {
      return (
        Number.isInteger(value) && Math.abs(value) <= Number.MAX_SAFE_INTEGER
      );
    };
  return Number.isSafeInteger(value);
}

function isSafe(value) {}

function isBetween(x, min, max = Number.MAX_VALUE) {
  return x >= min && x <= max;
}

export {formatNumber, isBetween, isInteger};
