function isNumeric(str) {
  if (typeof str != 'string') return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

function intToRGB(i) {
  var c = (i & 0x00ffffff).toString(16).toUpperCase();
  return '#' + '00000'.substring(0, 6 - c.length) + c;
}

function shortenString(text, length) {
  const segmentLength = parseInt((length - 3) / 2);
  const shortString =
    text.length > length
      ? `${text.substring(0, segmentLength)}...${text.substring(
          text.length - segmentLength,
          text.length,
        )}`
      : text;
  return shortString;
}

const callAll =
  (...fns) =>
  (...args) =>
    fns.forEach(fn => fn?.(...args));

function ifObjectIsEmpty(object) {
  var isEmpty = true;
  if (JSON.stringify(object) === JSON.stringify({})) {
    // Object is Empty
    isEmpty = true;
  } else {
    //Object is Not Empty
    isEmpty = false;
  }
  return isEmpty;
}

function pausecomp(millis) {
  var date = new Date();
  var curDate = null;
  do {
    curDate = new Date();
  } while (curDate - date < millis);
}

function getInitials(user_name) {
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
  var username = user_name ? user_name : '';
  let initials = [...username.matchAll(rgx)] || [];
  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase();
  return initials;
}

export {
  isNumeric,
  callAll,
  ifObjectIsEmpty,
  pausecomp,
  intToRGB,
  getInitials,
  shortenString,
};
