import React, { Component } from 'react';
import { connect } from 'react-redux';
import ArtifactLandingStyleWrapper from './artifact_landing.style';
import ArtifactDetailRoutes from './ArtifactDetailRoutes';

class ArtifactLanding extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }

  }

  componentDidMount() {
    
  }

  render() {
    return (
      <ArtifactLandingStyleWrapper className="isoArtifactLandingWrapper">
        <div className="formStyleWrapper">
          <ArtifactDetailRoutes />
        </div>
      </ArtifactLandingStyleWrapper>
    );
  }
}

export default connect()(ArtifactLanding);