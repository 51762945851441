import styled from "styled-components";
import WithDirection from "../../settings/withDirection";

const ArtifactLandingStyleWrapper = styled.div`
display: flex;
justify-content: center;
position: relative;

    .formStyleWrapper{
        max-width: 100%;
        margin: 0 auto;
        background-size: cover;
        background-color: #FFFFFF;
        background-position-y: top;
        width: 100%;
        //min-height: 90vh;
    }

    .isoArtifactLandingWrapper {
        
    }
`;

export default WithDirection(ArtifactLandingStyleWrapper);
