import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';

// import i18n (needs to be bundled ;))
import './i18n';

// import TimeAgo from 'javascript-time-ago'

// import en from 'javascript-time-ago/locale/en'
// import ru from 'javascript-time-ago/locale/ru'

// TimeAgo.addDefaultLocale(en)
// TimeAgo.addLocale(ru)

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
