import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const languageDetector = new LanguageDetector();

languageDetector.addDetector({
  name: 'languageByDomain',
  lookup: opts => {
    // const hostWithoutPort = (opts.headers.host || '').replace(/\:\d+$/, '');

    const hostWithoutPort = opts.htmlTag.baseURI;
    console.log('HOST NAME: ', hostWithoutPort);

    if (hostWithoutPort.includes('p1')) {
      return 'ar';
    } else if (hostWithoutPort.includes('p2')) {
      return 'zh';
    } else {
      return 'en';
    }
  },
  cacheUserLanguage: () => {
    /**/
  },
});

const DETECTION_OPTIONS = {
  // order and from where user language should be detected
  order: [
    // 'querystring',
    // 'cookie',
    // 'localStorage',
    // 'sessionStorage',
    // 'navigator',
    // 'htmlTag',
    // 'path',
    // 'subdomain',
    'languageByDomain',
  ],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'create.fandefi.com',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: {path: '/', sameSite: 'strict'},
};

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    detection: DETECTION_OPTIONS,
    fallbackLng: 'en',
    supportedLngs: ['ar', 'en', 'zh'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

i18n.changeLanguage();

export default i18n;
