import React, {useEffect} from 'react';
import {useArtifactDetails} from '../contexts/artifact-details-context';
import 'react-image-lightbox/style.css';
import ArtifactDetailStyleWrapper from '../artifact_details.style';
import Lightbox from 'react-image-lightbox';
import basicStyle from '../../../settings/basicStyle';
import Spin from '../../../helpers/spin.style';
import logo_ from '../../assets/images/fandefi.svg';
import {Row, Col} from 'antd';
import enlargeIcon from '../../assets/images/Artifact/enlarge.svg';
import shareIcon from '../../assets/images/Artifact/share.svg';
import etherscanIcon from '../../assets/images/logos/etherscan_icon_logo.svg';
import openseaIcon from '../../assets/images/logos/opensea_icon_logo.svg';
import ipfsIcon from '../../assets/images/logos/ipfs_icon_logo.svg';
import Button from '../../../components/uielements/button';
import ArtifactBuyButton from './artifact-buy-button';
import InputBuyCount from './input-buy-count';
import metamaskIcon from '../../assets/images/metamask.png';
import Popover from '../../../components/uielements/popover';
import PopoverWrapper from '../../Uielements/Popover/Popover.styles';
import WalletAddress from '../../../components/walletAddress';
import DetailsInfoPanel from '../views/details-info-panel';
import Web3 from 'web3';
import Image from 'rc-image';
import HoverVideoPlayer from 'react-hover-video-player';
import renderHTML from 'react-render-html';

//import Thumbler from 'video-thumb';
import videoIcon from '../images/videoIcon.svg';
import audioIcon from '../images/audioIcon.svg';
import imageIcon from '../images/imageIcon.svg';

import axios from 'axios';
import config from '../../../config/api.config';
import CollectablesDownloadView from '../components/CollectablesDownloadIView';
import {isMobile} from 'react-device-detect';
import DetailsProjectArtifacts from './details-project-artifacts';

export function DetailsLayoutDesktop(props) {
  const [data, setData] = useArtifactDetails();

  // React.useEffect(() => {
  //   if (data.artifact !== undefined) {
  //     checkWalletStatus();
  //   }
  // }, [data]);

  const {rowStyle, gutter} = basicStyle;
  const showOnEtherscan = e => {
    e.preventDefault();

    window.open(
      `${process.env.REACT_APP_ETHERSCAN_BASE_URL}/${data.artifact.artifactTokenMintTransaction_hash}`,
      '_blank',
    );
  };

  const showOnOpensea = e => {
    e.preventDefault();

    window.open(
      `${process.env.REACT_APP_OPENSEA_BASE_URL}/${data.artifact.artifactTokenContract_address}/${data.artifact.artifactTokenId}`,
      '_blank',
    );
  };

  const [wrongNetwork, setWrongNetwork] = React.useState(false);
  const [isOnRightNetwork, setIsOnRightNetwork] = React.useState(false);
  const [isWalletConnected, setIsWalletConnected] = React.useState(false);
  const [accounts, setAccounts] = React.useState();
  const [walletAddress, setWalletAddress] = React.useState('');
  const defaultPopoverText = 'Copy';
  const copiedPopoverText = 'Copied';
  const [isCopied, setIsCopied] = React.useState(false);
  const [showMetaMaskPopup, setShowMetaMaskPopup] = React.useState(false);
  const [showWarningOnMobileView, setShowWarningOnMobileView] =
    React.useState(false);
  const [isOpenLightbox, setIsOpenLightbox] = React.useState(false);

  const [selectedContent, setSelectedContent] = React.useState('image');
  const [contentMenu, setContentMenu] = React.useState([]);
  const [mainContent, setMainContent] = React.useState({});

  useEffect(() => {
    checkWalletStatus();
  }, []);

  useEffect(() => {
    createContentMenu();
  }, [selectedContent]);

  useEffect(() => {
    createContentMenu();
    createContent();
  }, [data.artifact.assets]);

  function createContentMenu() {
    if (data.artifact && data.artifact.assets) {
      const selectedStyle = {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        width: '44px',
        height: '44px',
        borderRadius: '6px',
        border: '2px solid #00cfff',
        padding: '4px',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
      };
      const unSelectedStyle = {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        width: '40px',
        height: '40px',
        borderRadius: '6px',
        padding: '4px',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      };
      const menu = [];
      Object.keys(data.artifact.assets).map((key, index) => {
        console.info({key});
        const item = data.artifact.assets[key];
        if (key === 'image') {
          menu.push(
            <div
              key="image"
              onClick={() => {
                setSelectedContent('image');
              }}
            >
              <div
                style={
                  selectedContent === 'image' ? selectedStyle : unSelectedStyle
                }
              >
                <img
                  src={imageIcon}
                  style={{
                    width: '32px',
                    alignSelf: 'center',
                    borderRadius: '0px',
                  }}
                />
              </div>
            </div>,
          );
        }

        if (key === 'audio') {
          menu.push(
            <div
              key="audio"
              onClick={() => {
                setSelectedContent('audio');
              }}
            >
              <div
                style={
                  selectedContent === 'audio' ? selectedStyle : unSelectedStyle
                }
              >
                <img
                  src={audioIcon}
                  style={{
                    width: '32px',
                    alignSelf: 'center',
                    borderRadius: '0px',
                  }}
                />
              </div>
            </div>,
          );
        }
        if (key === 'video') {
          menu.push(
            <div
              key="video"
              onClick={() => {
                setSelectedContent('video');
              }}
            >
              <div
                style={
                  selectedContent === 'video' ? selectedStyle : unSelectedStyle
                }
              >
                <img
                  src={videoIcon}
                  style={{
                    width: '32px',
                    alignSelf: 'center',
                    borderRadius: '0px',
                  }}
                />
              </div>
            </div>,
          );
        }
      });
      console.info({menu});

      setContentMenu(menu);
    }
  }

  function createContent() {
    if (data.artifact && data.artifact.assets) {
      const content = {};
      console.info({selectedContent});
      Object.keys(data.artifact.assets).map((key, index) => {
        const item = data.artifact.assets[key];
        // console.log({item});
        if (key === 'image') {
          content[key] = (
            <img
              alt="artifactImage"
              src={item.accessUrl}
              style={{
                maxHeight: '600px',
                width: 'auto',
                maxWidth: '100%',
                padding: '5px',
                boxShadow:
                  'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
                boxShadow:
                  'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
                cursor: 'pointer',
              }}
              onClick={() => {
                setIsOpenLightbox(true);
              }}
            />
          );
        }

        if (key === 'audio') {
          content[key] = (
            <HoverVideoPlayer
              videoSrc={item.accessUrl}
              type={item.mimeType}
              muted={false}
              loop={true}
              controls
              preload="metadata"
              sizingMode="video"
              focused={true}
              videoStyle={{
                height: '400px',
                width: '95%',
                alignSelf: 'center',
                padding: '5px',
                margin: '0 20px 0 20px',
              }}
            />
          );
        }
        if (key === 'video') {
          content[key] = (
            <HoverVideoPlayer
              videoSrc={item.accessUrl}
              type={item.mimeType}
              muted={false}
              loop={true}
              controls
              preload="metadata"
              sizingMode="video"
              focused={true}
              videoStyle={{
                maxHeight: '800px',
                maxWidth: '400px',
                maxWidth: '100%',
                resizeMode: 'cover',
                padding: '5px',
                borderRadius: '12px',
                boxShadow:
                  'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
              }}
            />
          );
        }
      });
      console.info({content});
      if (content.image === undefined) {
        content['image'] = (
          <img
            alt="artifactImage"
            src={data.artifact ? data.artifact.image_url : ''}
            style={{
              maxHeight: '600px',
              maxWidth: '100%',
              width: 'auto',
              padding: '5px',
              boxShadow:
                'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
              boxShadow:
                'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
              cursor: 'pointer',
            }}
            onClick={() => {
              setIsOpenLightbox(true);
            }}
          />
        );
      }
      setMainContent(content);
    }
  }

  const checkWalletStatus = async () => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      const accounts = await web3.eth.getAccounts();
      if (accounts[0]) {
        setIsWalletConnected(true);
        setWalletAddress(accounts[0]);
      }

      window.ethereum.on('accountsChanged', newAccounts => {
        const web3 = new Web3(window.ethereum);
        web3.eth.getAccounts().then(accounts => {
          if (accounts[0]) {
            setIsWalletConnected(true);
            setWalletAddress(accounts[0]);
          }
        });
      });

      if (accounts[0]) {
        await checkNetwork();
      }

      window.ethereum.on('chainChanged', async newChainId => {
        if (!document.hidden) {
          await checkNetwork();
        }
      });

      document.addEventListener('visibilitychange', async () => {
        if (!document.hidden) {
          await checkNetwork();
        }
      });
    }
  };

  const connectWallet = async () => {
    if (isMobile) {
      setShowWarningOnMobileView(true);
      return;
    }

    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        await checkNetwork();
      } else {
        setShowMetaMaskPopup(true);
      }
    } catch (err) {}
  };

  const checkNetwork = async () => {
    const web3 = new Web3(window.ethereum);
    const networkId = await web3.eth.net.getId();
    if (networkId != process.env.REACT_APP_BLOCKCHAIN_ID) {
      setWrongNetwork(true);
      setIsOnRightNetwork(false);
    } else {
      setWrongNetwork(false);
      setIsOnRightNetwork(true);
    }
  };

  const changeNetwork = async () => {
    closePopup();

    const web3 = new Web3(window.ethereum);
    const networkId = await web3.eth.net.getId();

    if (networkId !== process.env.REACT_APP_BLOCKCHAIN_ID) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{chainId: process.env.REACT_APP_BLOCKCHAIN_ID_HEX}],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            const network = {
              chainId: process.env.REACT_APP_BLOCKCHAIN_ID_HEX,
              chainName: process.env.REACT_APP_BLOCKCHAIN_NAME,
              nativeCurrency: {
                name: 'Matic',
                symbol: 'MATIC',
                decimals: 18,
              },
              rpcUrls: [process.env.REACT_APP_BLOCKCHAIN_URL],
              blockExplorerUrls: [
                process.env.REACT_APP_BLOCKCHAIN_EXPLORER_URL,
              ],
            };
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [network],
            });
          } catch (addError) {}
        }
      }
    }
  };

  const closePopup = e => {
    setShowMetaMaskPopup(false);
    setWrongNetwork(false);
    setShowWarningOnMobileView(false);
  };

  const content = (
    <PopoverWrapper>
      <p>{isCopied ? copiedPopoverText : defaultPopoverText}</p>
    </PopoverWrapper>
  );

  const copyWalletAddress = walletAddress => {
    navigator.clipboard.writeText(walletAddress);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <ArtifactDetailStyleWrapper className="isoArtifactDetailWrapper">
      <div className="topbar">
        <div className="logo_">
          <img src={logo_} />
        </div>

        {isWalletConnected ? (
          <div className="metamask">
            <div
              style={{
                display: 'flex',
                margin: '5px 0px',
                alignItems: 'center',
              }}
            >
              <img
                alt="MetaMask"
                src={metamaskIcon}
                style={{width: '33px', height: '33px'}}
              />

              <Popover content={content} title="" trigger="hover">
                <div onClick={() => copyWalletAddress(walletAddress)}>
                  <WalletAddress
                    walletAddress={walletAddress}
                    walletStyle="topbarWalletAddress"
                  />
                </div>
              </Popover>
            </div>
          </div>
        ) : (
          <div
            className="metamask"
            style={{cursor: 'pointer', paddingLeft: '0px'}}
            onClick={() => connectWallet()}
          >
            <div
              style={{
                display: 'flex',
                margin: '5px 0px',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '33px',
                textAlign: 'center',
              }}
            >
              <span
                style={{
                  color: '#FFFFFF',
                  font: 'normal normal bold 12px/16px Roboto',
                }}
              >
                CONNECT WALLET
              </span>
            </div>
          </div>
        )}
      </div>
      <Spin spinning={data.flags.loading} size="medium">
        <div className="artifactWrapper">
          <Row style={rowStyle} gutter={gutter} justify="start">
            <Col
              lg={12}
              md={12}
              sm={24}
              xs={24}
              className="columnStyle"
              style={{
                paddingLeft: '0px !important',
                paddingRight: '0px !important',
              }}
            >
              <div className="artifactImageWrapper">
                <div className="enlargeButtonWrapper">
                  {
                    // <Button type="primary" onClick={() => this.handleGallery(0)}>
                    //   <img alt="enlargeArtifactImage" src={enlargeIcon} />
                    // </Button>
                  }
                </div>
                <div style={{margin: '0 40px 0 40px'}}>
                  {mainContent[selectedContent]}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around',
                    }}
                  >
                    {contentMenu && contentMenu.length > 0 ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignContent: 'baseline',
                          padding: '20px',
                          gap: '20px',
                        }}
                      >
                        {Object.keys(contentMenu).length > 1
                          ? contentMenu
                          : null}
                      </div>
                    ) : null}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '20px',
                    }}
                  >
                    <CollectablesDownloadView />
                  </div>
                </div>
              </div>
            </Col>
            <Col
              lg={12}
              md={12}
              sm={24}
              xs={24}
              style={{
                paddingLeft: '0px !important',
                paddingRight: '0px !important',
              }}
            >
              <div className="artifactDetailsWrapper">
                <div className="artifactDetailContainer">
                  <div className="artifactBasicDetails">
                    <div className="artifactTitleWrapper">
                      <p className="artifactTitle">
                        {data.artifact ? data.artifact.name : ''}
                      </p>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justify: 'center',
                          alignContent: 'space-between',
                          alignItems: 'baseline',
                        }}
                      >
                        <div
                          style={{
                            paddingLeft: '0px',
                            font: 'normal normal 500 11px Roboto',
                            color: '#292929',
                          }}
                          className="artifactEditionLabel"
                        >
                          {data.flags.isCreator
                            ? 'YOU ARE THE CREATOR OF THIS ARTIFACT'
                            : null}
                        </div>
                        <div
                          style={{paddingLeft: '0px'}}
                          className="artifactEditionLabel"
                        >
                          {data.flags.wasCreatedByCurrencContract
                            ? null
                            : 'THIS ARTIFACT WAS CREATED USING A OLDER CONTRACT'}
                        </div>
                        <div className="artifactId">
                          #{data.artifact ? data.artifact.artifactTokenId : ''}
                        </div>
                        <div className="shareButtonWrapper">
                          <Button type="primary">
                            <img alt="shareIcon" src={shareIcon} />
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '10px 10px 15px 10px',
                        minWidth: '140px',
                        borderRadius: '8px',
                        border: '1px solid #00cfff',
                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
                      }}
                    >
                      <p
                        style={{
                          font: 'normal normal bold 22px Roboto',
                          color: '#292929',
                        }}
                      >
                        {data.artifact
                          ? data.flags
                            ? data.flags.isCreator
                              ? data.artifactLiveInfo.editionsSold
                              : data.flags.walletHolderEditionsOwned
                              ? data.flags.walletHolderEditionsOwned
                              : ''
                            : ''
                          : ''}
                      </p>
                      <p
                        style={{
                          font: 'normal normal 500 11px Roboto',
                          color: '#292929',
                        }}
                      >
                        {data.artifact
                          ? data.flags.isCreator
                            ? data.artifactLiveInfo.editionsSold === '1'
                              ? 'EDITION SOLD'
                              : 'EDITIONS SOLD'
                            : data.flags.walletHolderEditionsOwned
                            ? data.flags.walletHolderEditionsOwned === '1'
                              ? 'EDITION OWNED'
                              : 'EDITIONS OWNED'
                            : ''
                          : ''}
                      </p>
                      <p
                        style={{
                          font: 'normal normal bold 22px Roboto',
                          color: '#292929',
                          paddingTop: '5px',
                        }}
                      >
                        {data.artifact.editions
                          ? data.flags
                            ? data.flags.isCreator
                              ? ''
                              : data.artifactLiveInfo
                              ? data.artifact.editions -
                                data.artifactLiveInfo.editionsSold
                              : ''
                            : // data.artifactLiveInfo.editionsSold
                            data.artifact
                            ? data.artifact.editions
                            : ''
                          : ''}
                      </p>
                      <p
                        style={{
                          font: 'normal normal 500 11px Roboto',
                          color: '#292929',
                        }}
                      >
                        {data.artifact.editions
                          ? data.flags.isCreator
                            ? ''
                            : data.artifact.editions
                            ? data.artifactLiveInfo
                              ? data.artifact.editions -
                                  data.artifactLiveInfo.editionsSold ===
                                '1'
                                ? 'EDITIONS AVAILABLE'
                                : 'EDITIONS AVAILABLE'
                              : null
                            : null
                          : null}
                      </p>
                      <p
                        style={{
                          font: 'normal normal bold 22px Roboto',
                          color: '#292929',
                          paddingTop: '5px',
                        }}
                      >
                        {data.artifact.editions ? data.artifact.editions : ' '}
                      </p>
                      <p
                        style={{
                          font: 'normal normal 500 11px Roboto',
                          color: '#292929',
                        }}
                      >
                        {data.artifact.editions
                          ? data.artifact.editions === 1
                            ? 'EDITION MINTED'
                            : 'EDITIONS MINTED'
                          : ''}
                      </p>
                    </div>
                  </div>
                  <div className="artifactArtistDetails">
                    <span
                      style={{
                        font: 'normal normal 500 16px Roboto',
                        color: '#292929',
                      }}
                    >
                      DESCRIPTION
                    </span>
                    <p className="artifactArtistDesc">
                      {renderHTML(
                        decodeURIComponent(
                          data.artifact ? data.artifact.description : '',
                        ),
                      )}
                    </p>
                  </div>

                  <div className="artifactButtonsWrapper">
                    <a target="_blank" href={``}>
                      <Button type="primary" onClick={showOnEtherscan}>
                        <img alt="etherscanIcon" src={etherscanIcon} />
                        <span>View mint transaction</span>
                      </Button>
                    </a>

                    <a target="_blank" href={``}>
                      <Button
                        type="primary"
                        style={{marginTop: '10px'}}
                        onClick={showOnOpensea}
                      >
                        <img alt="ipfsIcon" src={openseaIcon} />
                        <span>View on OpenSea</span>
                      </Button>
                    </a>
                  </div>
                </div>
                <DetailsInfoPanel />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    flexWrap: 'wrap',
                    margin: '20px',
                    gap: '20px',
                    alignItems: 'baseline',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{
                        font: 'normal normal 500 11px Roboto',
                        color: '#292929',
                        textAlign: 'right',
                      }}
                    >
                      EACH EDITION
                    </div>
                    <div
                      style={{
                        font: 'normal normal bold 30px / 40px Roboto',
                        color: '#0e4799',
                      }}
                    >
                      {data.artifact ? data.artifact.price : 0}{' '}
                      {data.artifact.currency
                        ? data.artifact.currency == 1
                          ? 'USDC'
                          : data.artifact.currency == 2
                          ? 'MATIC'
                          : 'WETH'
                        : ''}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '20px',
                    }}
                  >
                    <InputBuyCount />
                    <ArtifactBuyButton />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div
          style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'row',

            gap: '20px',
          }}
        >
          <DetailsProjectArtifacts />
        </div>
      </Spin>

      {isOpenLightbox && (
        <Lightbox
          mainSrc={data && data.artifact ? data.artifact.image_url : ''}
          onCloseRequest={() => setIsOpenLightbox(false)}
        />
      )}

      {showMetaMaskPopup && <div className="modal_mask"></div>}
      {showMetaMaskPopup && (
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '94%',
            zIndex: '999999',
            left: '0px',
            top: '40%',
          }}
        >
          <div
            className="addPopup"
            style={{
              position: 'initial',
              paddingBottom: '13px',
              paddingTop: '17px',
              maxHeight: '100%',
            }}
          >
            <div className="popup_header" style={{position: 'relative'}}>
              <h4
                style={{
                  paddingRight: '15px',
                  fontWeight: 'bold',
                  fontSize: '18px',
                }}
              >
                Get MetaMask
              </h4>
              <span
                style={{fontWeight: 'bold', top: '0'}}
                onClick={() => closePopup()}
              ></span>
            </div>
            <div className="main">
              <span className="inputHeader">
                MetaMask is required to access your Ethereum / Polygon wallet
              </span>
            </div>
            <div className="isoButtonWrapper">
              <a
                target="_blank"
                href="https://metamask.io/download.html"
                className="ant-btn"
                onClick={() => closePopup()}
              >
                Get MetaMask
              </a>
            </div>
          </div>
        </div>
      )}

      {showWarningOnMobileView && <div className="modal_mask"></div>}
      {showWarningOnMobileView && (
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '94%',
            zIndex: '999999',
            left: '0px',
            top: '40%',
          }}
        >
          <div
            className="addPopup"
            style={{
              position: 'initial',
              paddingBottom: '13px',
              paddingTop: '17px',
              maxHeight: '100%',
            }}
          >
            <div className="popup_header" style={{position: 'relative'}}>
              <h4
                style={{
                  paddingRight: '15px',
                  fontWeight: 'bold',
                  fontSize: '18px',
                }}
              >
                You're on a mobile browser
              </h4>
              <span
                style={{fontWeight: 'bold', top: '0'}}
                onClick={() => closePopup()}
              ></span>
            </div>
            <div className="main">
              <span className="inputHeader">
                Oops, looks like you're on a mobile browser. We recommend using
                Chrome on desktop.
              </span>
            </div>
          </div>
        </div>
      )}

      {wrongNetwork && <div className="modal_mask"></div>}
      {wrongNetwork && (
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '94%',
            zIndex: '999999',
            left: '0px',
            top: '40%',
          }}
        >
          <div
            className="addPopup"
            style={{
              position: 'initial',
              paddingBottom: '13px',
              paddingTop: '17px',
              maxHeight: '100%',
            }}
          >
            <div className="popup_header" style={{position: 'relative'}}>
              <h4
                style={{
                  paddingRight: '15px',
                  fontWeight: 'bold',
                  fontSize: '18px',
                }}
              >
                Wrong network
              </h4>
              <span
                style={{fontWeight: 'bold', top: '0'}}
                onClick={() => closePopup()}
              ></span>
            </div>
            <div className="main">
              <span className="inputHeader"></span>
            </div>
            <div className="isoButtonWrapper">
              <Button type="primary" onClick={() => changeNetwork()}>
                Switch to {process.env.REACT_APP_BLOCKCHAIN_NAME}
              </Button>
            </div>
          </div>
        </div>
      )}
    </ArtifactDetailStyleWrapper>
  );
}
