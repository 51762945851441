import React, {Component} from 'react';
import ModalStyle from './modal.style';

function Modal(props) {
  const {icon, heading, message, closeModalPopup, closable, topPosition} =
    props;

  return (
    <ModalStyle>
      <div className="modal_mask">
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '94%',
            zIndex: '999999',
            left: '0px',
            top: topPosition ? topPosition : '40%',
          }}
        >
          <div
            className="addPopup"
            style={{
              position: 'initial',
              paddingBottom: '13px',
              paddingTop: '17px',
              maxHeight: '100%',
            }}
          >
            <div
              className="popup_header"
              style={{
                position: 'relative',
              }}
            >
              <i className={icon} style={{fontSize: '50px'}}></i>
              <h1 style={{paddingRight: '15px', fontWeight: 'bold'}}>
                {heading}
              </h1>
              <span
                style={{
                  fontWeight: 'bold',
                  top: '5px',
                  display: closable !== undefined ? 'none' : 'flex',
                }}
                onClick={closeModalPopup}
              ></span>
            </div>
            <div className="main" style={{maxHeight: '62vh'}}>
              {message}
            </div>
          </div>
        </div>
      </div>
    </ModalStyle>
  );
}

export default Modal;
