import styled from 'styled-components';
import {palette} from 'styled-theme';
import {transition, borderRadius} from '@iso/lib/helpers/style_utils';
import WithDirection from '@iso/lib/helpers/rtl';

import FandefiLogo from '../assets/images/fandefi.svg';
import FandefiArabicLogo from '../assets/images/arabic-language.svg';
import IamLogo from '../assets/images/icon-iam-logo.jpg';
import FandefiShortLogo from '../assets/images/fandefi_logo.svg';
import HamburgerIcon from '../assets/images/Menus/hamburger.svg';
import DashboardIcon from '../assets/images/Menus/dashboard.svg';
import ProjectsIcon from '../assets/images/Menus/projects.svg';
import AssetsIcon from '../assets/images/Menus/assets.svg';
import LandingPagesIcon from '../assets/images/Menus/landing.svg';
import FundsIcon from '../assets/images/Menus/funds.svg';
import FansIcon from '../assets/images/Menus/fans.svg';
import MarketPlaceIcon from '../assets/images/Menus/marketplace.svg';

import MarketPlaceProjectIcon from '../assets/images/Menus/marketplace_projects.svg';
import MarketPlaceArtIcon from '../assets/images/Menus/marketplace_art.svg';
import MarketPlaceMusicIcon from '../assets/images/Menus/marketplace_music.svg';
import MarketPlaceFilmsIcon from '../assets/images/Menus/marketplace_films.svg';
import MarketPlacePublishingIcon from '../assets/images/Menus/marketplace_publishing.svg';

const SidebarWrapper = styled.div`
  .isomorphicSidebar {
    z-index: 1000;
    //background: ${palette('secondary', 0)};
    border-right: 1px solid ${palette('border', 0)} !important;
    background: #ffffff;
    box-shadow: 0px 0px 9px #00000024;
    width: 280px;
    flex: 0 0 280px;

    .scrollarea {
      height: calc(100vh - 70px);
    }

    .logo {
      width: 118px;
      height: 30px;
      background-image: url(${FandefiLogo});
      background-repeat: no-repeat;
      background-size: 100%;
      display: block;
      cursor: pointer;
      margin-left: 10px;
      margin-top: 5px;
    }

    .arabicLogo {
      width: 50px;
      height: 50px;
      background-image: url(${FandefiArabicLogo});
      background-repeat: no-repeat;
      background-size: 100%;
      display: block;
      cursor: pointer;
      margin-left: 10px;
      margin-top: 5px;
    }

    .iamLogo {
      width: 50px;
      height: 50px;
      background-image: url(${IamLogo});
      background-repeat: no-repeat;
      background-size: 100%;
      display: block;
      cursor: pointer;
      margin-left: 10px;
      margin-top: 5px;
    }

    @media only screen and (max-width: 767px) {
      width: 240px !important;
      flex: 0 0 240px !important;
    }

    &.ant-layout-sider-collapsed {
      .logo {
        background-image: url(${FandefiShortLogo});
        width: 15px;
        height: 30px;
        margin: auto;
        background-size: cover;
        margin-left: 10px;
        margin-top: 5px;
        @media only screen and (max-width: 767px) {
          width: 0px;
        }
      }
      @media only screen and (max-width: 767px) {
        width: 0;
        min-width: 0 !important;
        max-width: 0 !important;
        flex: 0 0 0 !important;
      }
    }

    .isoLogoWrapper {
      height: 70px;
      //background: rgba(0, 0, 0, 0.3);
      background: #ffffff;
      margin: 0;
      padding: 0 10px;
      text-align: center;
      overflow: hidden;
      ${borderRadius()};

      h3 {
        a {
          font-size: 21px;
          font-weight: 300;
          line-height: 70px;
          letter-spacing: 3px;
          text-transform: uppercase;
          //color: ${palette('grayscale', 6)};
          color: #0e4799;
          display: block;
          text-decoration: none;
        }
      }
    }

    &.ant-layout-sider-collapsed {
      .isoLogoWrapper {
        padding: 0;

        h3 {
          a {
            font-size: 27px;
            font-weight: 500;
            letter-spacing: 0;
          }
        }
      }
    }

    .isoDashboardMenu {
      padding-top: 35px;
      padding-bottom: 35px;
      background: transparent;

      a {
        text-decoration: none;
        font-weight: 400;
      }

      .ant-menu-item {
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 24px;
        margin: 0;

        width: 90%;
        margin-left: 10px !important;
        border-radius: 5px;
      }

      .dashboard_icon {
        background-image: url(${DashboardIcon});
      }
      .projects_icon {
        background-image: url(${ProjectsIcon});
      }
      .assets_icon {
        background-image: url(${AssetsIcon});
      }
      .landing_pages_icon {
        background-image: url(${LandingPagesIcon});
      }
      .funds_icon {
        background-image: url(${FundsIcon});
      }
      .fans_icon {
        background-image: url(${FansIcon});
      }
      .market_place_icon {
        background-image: url(${MarketPlaceIcon});
      }
      .marketplace_projects_icon {
        background-image: url(${MarketPlaceProjectIcon});
      }
      .marketplace_art_icon {
        background-image: url(${MarketPlaceArtIcon});
      }
      .marketplace_music_icon {
        background-image: url(${MarketPlaceMusicIcon});
      }
      .marketplace_films_icon {
        background-image: url(${MarketPlaceFilmsIcon});
      }
      .marketplace_publishing_icon {
        background-image: url(${MarketPlacePublishingIcon});
      }

      .isoMenuHolder {
        display: flex;
        align-items: center;
        .custom_icon {
          margin: 0px 10px 0px 0px;
          img {
            height: 20px;
          }
        }
        i {
          font-size: 19px;
          //color: inherit;
          color: #0e4799;
          margin: ${props =>
            props['data-rtl'] === 'rtl' ? '0 0 0 30px' : '0 30px 0 0'};
          width: 18px;
          ${transition()};
          background-repeat: no-repeat;
          background-size: contain;
          background-position-y: center;
          height: 20px;
        }
      }

      .anticon {
        font-size: 18px;
        margin-right: 30px;
        //color: inherit;
        color: #0e4799;
        ${transition()};
      }

      .nav-text {
        font-size: 14px;
        //color: inherit;
        color: #0e4799;
        font-weight: 400;
        ${transition()};
      }

      .ant-menu-item-selected {
        //background-color: rgba(0, 0, 0, 0.4) !important;
        background-color: #b1f0ff !important;

        .anticon {
          //color: #fff;
          color: #0e4799;
        }

        i {
          //color: #fff;
          color: #0e4799;
        }

        .nav-text {
          //color: #fff;
          color: #0e4799;
        }
      }

      > li {
        &:hover {
          i,
          .nav-text {
            //color: #ffffff;
            color: #317eed;
          }
        }
      }
    }

    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      background: ${palette('secondary', 5)};
    }

    .ant-menu-submenu {
      margin-left: 10px;

      .ant-menu-item {
        background-color: #fff !important;
        a {
          color: #0e4799 !important;
        }
        &:hover {
          a {
            color: #317eed !important;
          }
        }
      }
    }

    .ant-menu-submenu-inline,
    .ant-menu-submenu-vertical {
      > .ant-menu-submenu-title {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 24px;

        > span {
          display: flex;
          align-items: center;
        }

        .ant-menu-submenu-arrow {
          left: ${props => (props['data-rtl'] === 'rtl' ? '25px' : 'auto')};
          right: ${props => (props['data-rtl'] === 'rtl' ? 'auto' : '25px')};

          &:before,
          &:after {
            width: 8px;
            ${transition()};
            background-color: #0e4799;
          }

          &:before {
            transform: rotate(-45deg) translateX(3px);
          }

          &:after {
            transform: rotate(45deg) translateX(-3px);
          }

          ${
            '' /* &:after {
            content: '\f123';
            font-family: 'Ionicons' !important;
            font-size: 16px;
            color: inherit;
            left: ${props => (props['data-rtl'] === 'rtl' ? '16px' : 'auto')};
            right: ${props => (props['data-rtl'] === 'rtl' ? 'auto' : '16px')};
            ${transition()};
          } */
          };
        }

        &:hover {
          .ant-menu-submenu-arrow {
            &:before,
            &:after {
              color: #ffffff;
            }
          }
        }
      }

      .ant-menu-inline,
      .ant-menu-submenu-vertical {
        > li:not(.ant-menu-item-group) {
          padding-left: ${props =>
            props['data-rtl'] === 'rtl' ? '0px !important' : '74px !important'};
          padding-right: ${props =>
            props['data-rtl'] === 'rtl' ? '74px !important' : '0px !important'};
          font-size: 13px;
          font-weight: 400;
          margin: 0;
          color: inherit;
          ${transition()};

          &:hover {
            a {
              // color: #ffffff !important;
            }
          }
        }

        .ant-menu-item-group {
          padding-left: 0;

          .ant-menu-item-group-title {
            padding-left: 100px !important;
          }
          .ant-menu-item-group-list {
            .ant-menu-item {
              padding-left: 125px !important;
            }
          }
        }
      }

      .ant-menu-sub {
        box-shadow: none;
        background-color: transparent !important;
      }
    }

    &.ant-layout-sider-collapsed {
      .nav-text {
        display: none;
      }
      .ant-menu-item {
        margin-left: 3px !important;
        width: calc(100% - 7px);
      }

      .ant-menu-submenu-inline > {
        .ant-menu-submenu-title:after {
          display: none;
        }
      }
      .ant-menu-submenu {
        margin-left: 3px !important;
        margin-left: 0px;
      }
      .ant-menu-submenu-vertical {
        > .ant-menu-submenu-title:after {
          display: none;
        }

        .ant-menu-sub {
          background-color: transparent !important;

          .ant-menu-item {
            height: 35px;
          }
        }
      }
    }
  }
`;

export default WithDirection(SidebarWrapper);
