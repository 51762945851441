import {actions} from './actions';

const initState = {
  selectedArtifact: {},
  usdcContract: {},
  wethContract: {},
  selectedProject: {},
  projectType: 'CrowdfundProject',
  projectId: '',
  projectName: '',
  projectDesc: '',
  projectCategoryType: '',
  projectFundingType: 'external',
  projectFunds: 1000,
  projectCurrency: {lookup_id: '1', description: 'USDC'},
  projectTeamRoyaltyPercentage: 50,
  projectBackersRoyaltyPercentage: 50,
  projectNumBackerTokens: 100,
  projectCollaborators: [],
  projectBackerToken: {},
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_SELECTED_PROJECT:
      return {
        ...state,
        selectedProject: action.selectedProject,
      };
    case actions.SET_SELECTED_ARTIFACT:
      return {
        ...state,
        selectedArtifact: action.selectedArtifact,
      };
    case actions.SET_USDC_CONTRACT:
      return {
        ...state,
        usdcContract: action.usdcContract,
      };
    case actions.SET_WETH_CONTRACT:
      return {
        ...state,
        wethContract: action.wethContract,
      };
    case actions.SET_PROJECT_TYPE:
      return {
        ...state,
        projectType: action.projectType,
      };
    case actions.SET_PROJECT_ID:
      return {
        ...state,
        projectId: action.projectId,
      };
    case actions.SET_PROJECT_NAME:
      return {
        ...state,
        projectName: action.projectName,
      };
    case actions.SET_PROJECT_DESC:
      return {
        ...state,
        projectDesc: action.projectDesc,
      };
    case actions.SET_PROJECT_CATEGORY_TYPE:
      return {
        ...state,
        projectCategoryType: action.projectCategoryType,
      };
    case actions.SET_PROJECT_FUNDS_USD:
      return {
        ...state,
        projectFunds: action.projectFunds,
      };

    case actions.SET_PROJECT_CURRENCY:
      return {
        ...state,
        projectCurrency: action.projectCurrency,
      };
    case actions.SET_PROJECT_TEAM_ROYALTY_PERCENTAGE:
      return {
        ...state,
        projectTeamRoyaltyPercentage: action.projectTeamRoyaltyPercentage,
      };
    case actions.SET_PROJECT_BACKERS_ROYALTY_PERCENTAGE:
      return {
        ...state,
        projectBackersRoyaltyPercentage: action.projectBackersRoyaltyPercentage,
      };
    case actions.SET_PROJECT_NUM_BACKER_TOKENS:
      return {
        ...state,
        projectNumBackerTokens: action.projectNumBackerTokens,
      };
    case actions.SET_PROJECT_COLLABORATORS:
      return {
        ...state,
        projectCollaborators: action.projectCollaborators,
      };
    case actions.SET_PROJECT_BACKER_TOKEN:
      return {
        ...state,
        projectBackerToken: action.projectBackerToken,
      };
    case actions.SET_PROJECT_TRANSACTION_HASH:
      return {
        ...state,
        projectTransactionHash: action.projectTransactionHash,
      };
    case actions.CLEAR_ALL:
      return {
        ...state,
        ...initState,
      };
    default:
      return state;
  }
};

export default reducer;
