import React from 'react';
import {useArtifactDetails} from '../contexts/artifact-details-context';

import HoverImage from 'react-hover-image';
import fileIcon from '../images/file.svg';
import downloadIcon from '../images/download.svg';
import audioIcon from '../images/headphones.svg';
import videoIcon from '../images/film.svg';
import AspectRatio from 'react-aspect-ratio';
import {saveState} from '../../../library/helpers/localStorage';

function CollectablesDownloadView() {
  const [data, setData] = useArtifactDetails();

  const [fileList, setFileList] = React.useState();

  function downloadFile(e) {
    e.preventDefault();
    console.info({key: e.target.parentNode.getAttribute('file_name')});
    const index = e.target.parentNode.getAttribute('file_name');
    window.open(data.artifactLiveInfo.lockedContent[index].accessUrl, '_blank');
  }

  React.useEffect(() => {
    console.info({data});
    //setFileList(data.artifactLiveInfo.lockedContent);
    console.info({
      content: data.artifactLiveInfo
        ? data.artifactLiveInfo.lockedContent
        : 'still loading',
    });

    if (
      data.artifactLiveInfo !== undefined &&
      data.artifactLiveInfo.lockedContent !== undefined &&
      data.artifactLiveInfo.lockedContent.length > 0
    ) {
      console.info({content: data.artifactLiveInfo.lockedContent});
      const list = data.artifactLiveInfo.lockedContent
        .filter(item => {
          return item.accessType === 'restricted';
        })
        .map((item, index) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '10px',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
            key={index}
            file_name={index}
          >
            <AspectRatio
              ratio="1/1"
              style={{maxWidth: '30px', marginRight: '10px'}}
              file_name={index}
            >
              <HoverImage
                onClick={downloadFile}
                style={{
                  cursor: 'pointer',
									borderRadius: '2px',
									padding: '3px',
                  height: '30px',
                  width: '30px',
                  boxShadow:
                    'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
                }}
                file_name={index}
                src={
                  item.mimeType.startsWith('image')
                    ? item.accessUrl
                    : item.mimeType.startsWith('audio')
                    ? audioIcon
                    : item.mimeType.startsWith('video')
                    ? videoIcon
                    : fileIcon
                }
                hoverSrc={downloadIcon}
              />
            </AspectRatio>
            <span
              style={{
                color: '#00cfff',
                fontWeight: 'normal',
                fontSize: '16px',
                marginRight: '10px',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              file_name={index}
            >
              <div onClick={downloadFile} file_name={index}>
                {item.fileName.length > 25
                  ? `${item.fileName.substring(0, 10)}...
			            ${item.fileName.substring(
                    item.fileName.length - 10,
                    item.fileName.length,
                  )}`
                  : item.fileName}
              </div>
            </span>
            <div file_name={index}>
              <span
                style={{
                  cursor: 'pointer',
                  fontWeight: 'normal',
                  fontSize: '16px',
                  marginRight: '10px',
                }}
                onClick={downloadFile}
                file_name={index}
              >
                {' ('}
                {(item.sizeInBytes / (1024 * 1024)).toFixed(2)} MB )
              </span>
            </div>
          </div>
        ));
      setFileList(list);
    }

    // console.info({list});
  }, [data.artifactLiveInfo]);
  // });
  // console.info({fileList});
  if (fileList !== undefined) {
    return fileList.length > 0 ? (
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          borderRadius: '12px',
          padding: '20px',
          margin: '0px',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
        }}
      >
        <div
          style={{
            font: 'normal normal 500 16px Roboto',
            marginBottom: '20px',
            textAlign: 'left',
          }}
        >
          LOCKED CONTENT
        </div>
        {fileList}
      </div>
    ) : (
      <div></div>
    );
  } else {
    return <div />;
  }
}

export default CollectablesDownloadView;
