import getNextUrl from '../lib/next';

export default {
  BASE_URL: process.env.REACT_APP_API_BASE_URL,
  NEXT_BASE_URL: getNextUrl(''),
  USER_REGISTER: 'users/register',
  USER_LOGIN: 'users/login',
  PASSWORD_RESET_REQUEST: 'users/requestpasswordreset',
  PASSWORD_RESET: 'users/resetpassword',
  USER_DETAILS: 'users/',
  USER_BY_EMAIL: 'users/getuserbyemail',

  WALLET_CREATE: 'wallets/create',
  WALLET_GET_BY_USER_ID: 'wallets/getuserwallet',

  PROJECT_CREATE: 'projects/create',
  PROJECTS_GET_COUNT: 'projects/getcount',
  PROJECTS_LIST: 'projects/list',
  PROJECT_GET: 'projects/',
  PROJECT_FIND: 'projects',
  STOREFRONT_LIST: 'projects/listall',
  PROJECTS: 'projects',

  UPLOAD_FILE_TO_S3: 'misc/uploadTos3',

  ARTIFACT_CREATE: 'artifact/create',
  ARTIFACT_GET_COUNT: 'artifact/getcount',
  ARTIFACT_LIST: 'artifact/list',
  ARTIFACT_GET: 'artifact/',

  ASSET_CREATE: 'asset/create',
  ASSET_GET_RESTRICTED: 'asset/getrestricted',
  ASSET_GET_PUBLIC: 'asset/getpublic',

  FANFINGER_COUNT_ALL: 'fanfinger/countall',
  FANFINGER_COUNT_AVAILABLE: 'fanfinger/countunassigned',
};
