import styled from "styled-components";
import { palette } from "styled-theme";

const WalletAddressStyle = styled.div`
    display: flex;
    justify-content: center;
    position: relative;

    .walletAddress {
        color: #6A6A6A;
        //font: normal normal normal 10px Roboto;
        font: normal normal normal 10px/14px Roboto !important;
        cursor: pointer;
        width: 100%;
    }

    .topbarWalletAddress {
        color: #FFFFFF;
        font: normal normal bold 12px/16px Roboto;
        cursor: pointer;
        margin-left: 10px;
    }
}
`;

export default WalletAddressStyle;
