import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProjectLandingStyleWrapper from './project_landing.style';
import ProjectDetailRoutes from './ProjectDetailRoutes';

class ProjectLanding extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }

  }

  componentDidMount() {
    
  }

  render() {
    return (
      <ProjectLandingStyleWrapper className="isoProjectLandingWrapper">
        <div className="formStyleWrapper">
          <ProjectDetailRoutes />
        </div>
      </ProjectLandingStyleWrapper>
    );
  }
}

export default connect(
  
)(ProjectLanding);