import React from 'react';
import {useArtifactDetails} from '../contexts/artifact-details-context';
import {formatNumber} from '../utils/math';

export default function InputBuyCount(props) {
  const [data, setData] = useArtifactDetails();
  const [count, setCount] = React.useState(0);
  const [totalCost, setTotalCost] = React.useState(0);

  React.useEffect(() => {
    setData({
      ...data,
      flags: {...data.flags, editionsToBuy: count, totalCost: totalCost},
    });
  }, [totalCost]);

  function onChangeHandler(event) {
    const {value} = event.target;
    if (value >= 0) {
      processValueChange(value);
    }
  }

  function processValueChange(value) {
    const formattedValue = formatNumber(
      value,
      data.artifact.currency === 1 ? process.env.REACT_APP_USDC_DECIMALS : 6,
    );
    if (
      formattedValue >= 0 && formattedValue <= data.artifactLiveInfo
        ? data.artifact.editions - data.artifactLiveInfo.editionsSold
        : data.artifact
        ? data.artifact.editions
        : 100
    ) {
      setCount(parseInt(formattedValue));
      const cost = formatNumber(
        value * data.artifact.price,
        data.artifact.currency === 1 ? process.env.REACT_APP_USDC_DECIMALS : 6,
      );
      setTotalCost(cost);
      // setData({
      //   ...data,
      //   flags: {...data.flags, editionsToBuy: value, totalCost: cost},
      // });
    }
  }

  function onMouseWheel(event) {
    event.currentTarget.blur();
    event.preventDefault();
  }

  function handleScroll(event) {
    if (event.deltaY > 0) {
      if (count > 0) {
        processValueChange(count - 1);
      }
      //setCount(count - 1);
    } else {
      processValueChange(count + 1);
      //setCount(count + 1);
    }
  }

  // function enableScroll() {
  //   console.info('mouse left');
  //   document.removeEventListener('wheel', preventDefault, false);
  // }
  // function disableScroll() {
  //   document.addEventListener('wheel', preventDefault, {
  //     passive: false,
  //   });
  // }

  // const preventDefault = e => {
  //   e = e || window.event;
  //   if (e.preventDefault) {
  //     e.preventDefault();
  //   }
  //   e.returnValue = false;
  // };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        borderRadius: '12px',
        border: '1px solid #00CFFF',
        padding: '0px',
        alignContent: 'flex-start',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
      }}
      // onWheel={handleScroll}
      // onMouseEnter={disableScroll}
      // onMouseLeave={enableScroll}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <label
          style={{
            margin: '0px',
            textAlignVertical: 'top',
            font: 'normal normal 500 11px Roboto',
            color: '#292929',
            textAlign: 'right',
            marginRight: '10px',
            marginTop: '10px',
          }}
        >
          <p>EDITIONS TO BUY</p>
        </label>
        <div>
          <input
            id="counter"
            value={count}
            type="number"
            onChange={onChangeHandler}
            style={{
              marginLeft: '10px',
              marginRight: '10px',
              type: 'number',
              border: '0px',
              outline: '0px',
              textAlign: 'right',
              fontSize: '2em',
              fontWeight: 'bold',
              width: '100px',
            }}
          />
        </div>
      </div>
      <div
        style={{
          backgroundColor: '#00CFFF',
          width: '1px',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      ></div>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div
          style={{
            textAlign: 'left',
            font: 'normal normal 500 11px Roboto',
            color: '#292929',
            marginLeft: '10px',
            marginTop: '10px',
          }}
        >
          TOTAL COST IN{' '}
          {data.artifact.currency
            ? data.artifact.currency == 1
              ? 'USDC'
              : data.artifact.currency == 2
              ? 'MATIC'
              : 'WETH'
            : ''}
        </div>
        <input
          disabled={true}
          id="counter"
          value={totalCost > 0 ? totalCost : ''}
          type="number"
          style={{
            marginLeft: '10px',
            marginRight: '10px',
            type: 'number',
            border: '0px',
            outline: '0px',
            textAlign: 'left',
            fontSize: '2em',
            fontWeight: 'bold',
            width: '160px',
          }}
        />
      </div>
    </div>
  );
}
