import styled from "styled-components";
import { palette } from "styled-theme";

const ModalStyle = styled.div`
display: flex;
justify-content: center;
position: relative;

.modal_mask{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
} 


.addPopup{
  max-width: 500px;
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
  top: calc((100vh - 634px)/2);
  position: relative;
  background: #ffffff;
  text-align: center;
  padding: 20px 15px 29px 15px;
  font-size: 13px;
  color: #414141;
  line-height: 1.5;
  border-radius: 9px;
  @media only screen and (max-width: 480px) {
      border-radius: 0px;
  }

  .popup_header{
      font-size:16px;
      color: #414141;
      font-weight: 500;
      border-bottom: 1px solid #eaeaea;
      margin-bottom:13px;
      padding-bottom: 15px;
      padding-left: 15px;
      h4{
          text-align:left;
          color: #414141;
      }
      span{
          position: absolute;
          right: 20px;
          top: 19px;
          font-size: 19px;
          cursor:pointer;
          &:before{
              content: "\\292C";
              position:relative;
              bottom:4px;
              left: 3px;
              color: gray;
          }
      }
  }
  .main{
      max-height: 490px;
      overflow-y: auto;
      //border-bottom: 1px solid #eaeaea;
      padding: 0px 15px;
      span{
          color:${palette('gray', 9)};
          font-weight: 500;
          font-size: 14px;
          text-align: left;
          display: block;
      }
      .ant-input{
          height: 44px;
          margin-bottom: 20px;
          border-color:${palette('gray', 3)};
      }
  }
  .cancel_btn{
      color: ${palette("primary1", 4)} !important;
      background-color: #ffffff;
      border-color:${palette("primary1", 4)};
      font-weight: 500;
      margin-right: 10px;
      transition: all .3s;
      &:hover{
          font-size: 14.4px;
          background-color:#fbfbfb;
      }
  }
  .isoButtonWrapper{
      margin-top: 20px;
      padding-right: 15px;
      text-align: right;

      .ant-btn{
          vertical-align: top;
          width: 100%;
          padding: 0;
          height: 54px;
          border-radius: 4px;
          text-transform: uppercase;
          font-size: 18px;
          font-weight: 600;
          margin-top: 0px;
          max-width:190px;
          
          @media only screen and (max-width: 480px) {
              max-width: 480px !important;
              height: 47px;
              font-size: 16px !important;
          }
          &.save{
              border-color: #00CFFF;
              background-color: #00CFFF;
              &:hover{
                  border-color: #00C0ED !important;
                  background-color: #00C0ED !important;
              }
          }
          &.cancel{
              border-color: #00CFFF;
              background-color: #ffffff;
              margin-right: 25px;
              color: #00CFFF !important;
              &:hover{
                 font-size:  18.4px;
                 background-color: #ffffff;
              }
          }
          &.large_btn{
              max-width: 250px;
              @media only screen and (max-width: 600px) {
                  max-width: 190px;
                  font-size: 14px;
              }
          }
      }
  } 

  .inputHeader {
      color: #414141;
      font-size: 12px;
      font-weight: 500;
  }

  input{
      width: 100%;
      border: 1px solid #00CFFF !important;
      background: transparent !important;
      box-shadow: none !important;
      font-size: 12px;
      font-weight: 500;
      margin-top: 5px;
      border-radius: 6px !important;
      height: 40px !important;
      color: #414141 !important;  
      font: Roboto;
  }
}
`;

export default ModalStyle;
