import {actions} from './actions';

const initState = {
  userProfile: {},
  walletAddress: '',
  authToken: '',
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.userProfile,
      };
    case actions.SET_WALLET_ADDRESS:
      return {
        ...state,
        walletAddress: action.walletAddress,
      };
    case actions.SET_AUTH_TOKEN:
      return {
        ...state,
        authToken: action.authToken,
      };
    case actions.CLEAR_ALL:
      return {
        ...state,
        ...initState,
      };
    default:
      return state;
  }
};

export default reducer;
