import React from 'react';
import {connect} from 'react-redux';

import {ArtifactDetailsProvider} from './contexts/artifact-details-context';
import {DetailsLayoutDesktop} from './views/layout-details-desktop';

require('dotenv').config({
  path: `../.env.${process.env.NODE_ENV}`,
});

function ArtifactDetails(props) {
  

  return (
    <ArtifactDetailsProvider artifact={{id: props.match.params.artifactId}}>
      <DetailsLayoutDesktop />
    </ArtifactDetailsProvider>
  );
}

const mapStateToProps = state => {
  return {
    // selectedArtifact: state.ProjectsReducer.selectedArtifact,
    // web3User: state.Web3Reducer.web3User,
    // backerTokenContract: state.Web3Reducer.backerTokenContract,
    // tokenManagerContract: state.Web3Reducer.tokenManagerContract,
    // usdcContract: state.Web3Reducer.usdcContract,
    // wethContract: state.Web3Reducer.wethContract,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // setSelectedArtifact: selectedArtifact =>
    //   dispatch(actions.setSelectedArtifact(selectedArtifact)),
    // setWeb3Provider: web3Provider =>
    //   dispatch(actions.setWeb3Provider(web3Provider)),
    // setWeb3User: web3User => dispatch(actions.setWeb3User(web3User)),
    dispatch,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ArtifactDetails);

//export default connect()(ArtifactDetails);
