import React from 'react';
import {useArtifactDetails} from '../contexts/artifact-details-context';
import {getInitials, shortenString} from '../utils/utils';
import randomColor from 'randomcolor';
import ReactTooltip from 'react-tooltip';
import './details-info-panel.css';
import {RangeStatic} from 'quill';
import Image from 'rc-image';
import {
  PRIVATE_ROUTE,
  PUBLIC_ROUTE,
  PROJECT_ROUTE,
} from '../../../route.constants';
export default function DetailsProjectArtifacts(props) {
  const [data, setData] = useArtifactDetails();

  const artifacts = [];

  // ARTIFACT RENDERS
  if (data.project) {
    data.project.artifacts.forEach((artifact, index) => {
      const image_url = artifact.image_url;
      const artifact_link = `${process.env.REACT_APP_URL}${PROJECT_ROUTE.ARTIFACT_LANDING}${artifact._id}`;
      if (artifact._id !== data.artifact._id) {
        artifacts.push(
          <div
            key={artifact._id}
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '300px',
              maxWidth: '300px',
              borderRadius: '4px',
              // boxShadow:
              // 	'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <a href={artifact_link} key={artifact._id}>
              <Image
                key={artifact._id}
                src={image_url}
                preview={false}
                style={{
                  padding: '2px',
                  borderRadius: '4px',
                  maxHeight: '300px',
                  maxWidth: '300px',
                  boxShadow:
                    'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px',
                  // boxShadow:
                  //   'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                }}
              />
            </a>
          </div>,
        );
      }
    });
  }
  if (data.project && data.project.artifacts.length > 1) {
    return (
      <div style={{marginBottom: '20px'}}>
        <div
          style={{
            font: 'normal normal 500 14px Roboto ',
          }}
        >
          MORE FROM THIS PROJECT
        </div>
        <div
          style={{
            margin: '10px 0px 10px 0px',
            margin: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            height: '350px',
            flexWrap: 'wrap',
            gap: '20px',
          }}
        >
          {artifacts}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
