import styled from "styled-components";
import { palette } from "styled-theme";
import WithDirection from "../../settings/withDirection";
import Back_icon from '../assets/images/back_icon.svg';

const ProjectLandingStyleWrapper = styled.div`
display: flex;
justify-content: center;
position: relative;

    .formStyleWrapper{
        max-width: 100%;
        margin: 0 auto;
        background-size: cover;
        background-color: #FAFAFA;
        background-position-y: top;
        width: 100%;
        min-height: 90vh;
    }

    .isoProjectLandingWrapper {
        
    }
`;

export default WithDirection(ProjectLandingStyleWrapper);
