import React from 'react';

const ArtifactDetailsContext = React.createContext();

// get children via props
function ArtifactDetailsProvider(props) {
  const [data, setData] = React.useState({
    artifact: props.artifact,
    flags: {readBlockchain: false},
    // flags: {
    //   installMetamask: false,
    //   changeNetwork: false,
    //   error: false,
    //   initContracts: false,
    //  },
    // dialog: {
    //   title: '',
    //   message: '',
    // },
  });
  const value = [data, setData];
  return <ArtifactDetailsContext.Provider value={value} {...props} />;
}

const useArtifactDetails = () => {
  const context = React.useContext(ArtifactDetailsContext);
  if (!context) {
    throw new Error(
      'useArtifactCreate must be used withing an ArtifactDataProvider element',
    );
  }
  return context;
};

export {ArtifactDetailsProvider, useArtifactDetails};
