
import React, { useEffect } from 'react';
import {
    Route,
    Redirect,
    BrowserRouter as Router,
    Switch,
    useHistory
  } from 'react-router-dom';
  
  
  function NotFoundPage({ CustomNotFoundPage }) {
    const history = useHistory();
    useEffect(() => {
      history.push("/notFound");
    }, []);
    return null;
  }
  export default function CustomSwitch(props) {
    const { children } = props;
    return (
      <Switch>
        {children}
        <NotFoundPage />
      </Switch>
    );
  }