import React from 'react';
import {Link, useRouteMatch} from 'react-router-dom';

import Menu from '@iso/components/uielements/menu';
import IntlMessages from '@iso/components/utility/intlMessages';
import {useTranslation, Trans} from 'react-i18next';
import getNextUrl from '../../lib/next';

const SubMenu = Menu.SubMenu;

const stripTrailingSlash = str => {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }
  return str;
};
export default React.memo(function SidebarMenu({
  singleOption,
  submenuStyle,
  submenuColor,
  ...rest
}) {
  let match = useRouteMatch();
  const {t, i18n} = useTranslation();

  const {key, label, leftIcon, children} = singleOption;
  const url = stripTrailingSlash(match.url);

  if (children) {
    return (
      <SubMenu
        key={key}
        title={
          <span className="isoMenuHolder" style={submenuColor}>
            <i className={leftIcon} />
            <span className="nav-text">
              <IntlMessages id={label} />
            </span>
          </span>
        }
        {...rest}
      >
        {children.map(child => {
          // const linkTo = child.withoutDashboard
          //   ? `/${child.key}`
          //   : `${url}/${child.key}`;
          const linkTo = child.withoutDashboard
            ? `/${child.key}`
            : `${url}/${child.key}`;
          return (
            <Menu.Item style={submenuStyle} key={child.key}>
              <Link style={submenuColor} to={linkTo}>
                <IntlMessages id={child.label} />
              </Link>
            </Menu.Item>
          );
        })}
      </SubMenu>
    );
  }

  const linkTo = key === 'marketplace' ? `/projects` : `${url}/${key}`;
  // const targetTo = (key === 'marketplace') ? `_blank` : ``;

  return (
    <Menu.Item key={key} {...rest}>
      {/* <Link to={linkTo} target={targetTo}> */}
      {key === 'marketplace' || key === 'funds' ? (
        <a href={key === 'marketplace' ? getNextUrl('') : getNextUrl('/funds')}>
          <span className="isoMenuHolder" style={submenuColor}>
            <i className={leftIcon} />
            <span className="nav-text">
              {/* <IntlMessages id={label} /> */}
              {t(label)}
            </span>
          </span>
        </a>
      ) : (
        <Link to={linkTo}>
          <span className="isoMenuHolder" style={submenuColor}>
            <i className={leftIcon} />
            <span className="nav-text">
              {/* <IntlMessages id={label} /> */}
              {t(label)}
            </span>
          </span>
        </Link>
      )}
    </Menu.Item>
  );
});
