import React, {Component} from 'react';
import WalletAddressStyle from './walletAddress.style';

function WalletAddress(props) {
  // console.info({props});
  const {walletAddress, walletStyle} = props;
  const maskedWalletAddress =
    !walletAddress || walletAddress === ''
      ? ``
      : `${walletAddress.slice(0, 6)}...${walletAddress.slice(-4)}`;

  return (
    <WalletAddressStyle>
      <p
        className={
          !walletStyle || walletStyle === '' ? 'walletAddress' : walletStyle
        }
      >
        {maskedWalletAddress}
      </p>
    </WalletAddressStyle>
  );
}

export default WalletAddress;
