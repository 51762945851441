import React, { Component } from 'react';
import { connect } from 'react-redux';
import StorefrontLandingStyleWrapper from './storefront_landing.style';
import StorefrontRoutes from './StorefrontRoutes';

class StorefrontLanding extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    return (
      <StorefrontLandingStyleWrapper className="isoProjectLandingWrapper">
        <div className="formStyleWrapper">
          <StorefrontRoutes />
        </div>
      </StorefrontLandingStyleWrapper>
    );
  }
}

export default connect(
  
)(StorefrontLanding);