export const actions = {
  SET_USER_PROFILE: 'SET_USER_PROFILE',
  SET_WALLET_ADDRESS: 'SET_WALLET_ADDRESS',
  SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
  CLEAR_ALL: 'CLEAR_ALL',
  setUserProfile: userProfile => {
    return {
      type: actions.SET_USER_PROFILE,
      userProfile,
    };
  },
  setWalletAddress: walletAddress => {
    return {
      type: actions.SET_WALLET_ADDRESS,
      walletAddress,
    };
  },
  setAuthToken: authToken => {
    return {
      type: actions.SET_AUTH_TOKEN,
      authToken,
    };
  },
  clearAll: () => {
    return {
      type: actions.CLEAR_ALL,
    };
  },
};
