import React, { lazy, Suspense } from 'react';
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import Loader from '@iso/components/utility/loader';
import { PROJECT_ROUTE } from '../../route.constants';
import CustomSwitch from './../../customSwitch';

const routes = [
  {
    path: `${PROJECT_ROUTE.ARTIFACT_LANDING}:artifactId`,
    component: lazy(() => import('@iso/containers/Artifact/artifact_details')),
  }, 
  {
    path: `${PROJECT_ROUTE.ARTIFACT_DETAILS}`,
    component: lazy(() => import('@iso/containers/Artifact/artifact_details')),
  },
];

export default function AppRouter() {
  const { url } = useRouteMatch();
  
  return (
    <Suspense fallback={<Loader />}>
      <CustomSwitch>
      {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute; 
          return (
            <Route 
              key={singleRoute.path}
              path={singleRoute.path}
              {...otherProps} 
            />
          );
        })}
      </CustomSwitch>
    </Suspense>
  );
}
