import React from 'react';
import {useArtifactDetails} from '../contexts/artifact-details-context';
import {getInitials, shortenString} from '../utils/utils';
import randomColor from 'randomcolor';
import ReactTooltip from 'react-tooltip';
import './details-info-panel.css';
import {RangeStatic} from 'quill';
import {
  PRIVATE_ROUTE,
  PUBLIC_ROUTE,
  PROJECT_ROUTE,
} from '../../../route.constants';
export default function DetailsInfoPanel(props) {
  const [data, setData] = useArtifactDetails();
  const [tooltip, setTooltip] = React.useState('copy wallet address');

  const collaborator_renders = [];
  const backer_renders = [];

  // Collaborators
  if (
    data.artifact &&
    data.project &&
    data.project.team_collaborators &&
    data.artifact.artifactTokenCreator_address
  ) {
    // console.log({data});
    data.project.team_collaborators.forEach((member, index) => {
      console.info({member});
      const member_render = render_collaborators(
        index,
        member.wallet_address,
        member.role,
        member.user_name,
      );
      if (member.wallet_address === data.artifact.creator) {
        collaborator_renders.unshift(member_render);
      } else {
        collaborator_renders.push(member_render);
      }
    });
  }

  // BACKERS
  if (data.backerTokenLiveInfo !== undefined) {
    // console.log({info: data.backerTokenLiveInfo.holders});
    if (data.backerTokenLiveInfo.holders.length > 0) {
      for (
        let index = 0;
        index < data.backerTokenLiveInfo.holders.length && index < 3;
        index++
      ) {
        const member = data.backerTokenLiveInfo.holders[index];
        // console.log({member});
        const backer_render = render_backers(index, member);
        backer_renders.push(backer_render);
      }
    } else {
    }
  }

  function render_collaborators(index, wallet_address, role, name) {
    const color = randomColor({
      seed: name,
      luminosity: 'light',
      format: 'rgb',
    });
    const initials = getInitials(name);
    const address = shortenString(wallet_address, 16);
    const html = (
      <div
        key={index}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          justifyItems: 'center',
          gap: '2px',
        }}
      >
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div
            style={{
              height: '75px',
              width: '75px',
              borderRadius: '50%',
              //border: '1px solid #00CFFF',
              backgroundColor: color,
              color: 'white',
              font: 'normal normal 500 24px Roboto',
              textAlign: 'center',
              padding: '24px 0',
              marginBottom: '10px',
              boxShadow:
                'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
            }}
          >
            {initials}
          </div>
        </div>
        <div
          style={{
            font: 'normal normal 500 14px Roboto ',
          }}
        >
          {name}
        </div>
        <div
          style={{
            font: 'normal normal 500 14px Roboto ',
            color: '#00CFFF',
          }}
        >
          {role.toUpperCase()}
        </div>

        <div
          data_tip="click to copy"
          onClick={() => {
            navigator.clipboard.writeText(wallet_address);
          }}
          style={{
            font: 'normal normal 500 10px Roboto ',
          }}
        >
          <a data-tip={tooltip}> {address}</a>
          <ReactTooltip place="bottom" type="dark" effect="solid" />
        </div>
      </div>
    );
    return html;
  }

  function render_backers(index, wallet_address) {
    const color = randomColor({
      seed: wallet_address,
      luminosity: 'light',
      format: 'rgb',
    });

    const address = shortenString(wallet_address, 12);
    const html = (
      <div key={index}>
        <div
          className={'backerN'}
          style={{
            boxShadow:
              'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
            // boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            // boxShadow:
            //   'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
            height: '75px',
            width: '75px',
            borderRadius: '50%',
            //border: '1px solid #00CFFF',
            backgroundColor: color,
            color: 'white',

            textAlign: 'center',
            padding: '24px 0',
            marginBottom: '10px',
          }}
        >
          <div
            style={{
              paddingTop: '8px',
              font: 'normal normal 500 11px Roboto',
            }}
          >
            {address}
          </div>
        </div>
      </div>
    );
    return html;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-start',
          borderRadius: '12px',
          padding: '20px',
          margin: '20px',
          //border: '1px solid #00CFFF',
          alignContent: 'flex-start',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
        }}
      >
        <div
          style={{
            font: 'normal normal 500 16px Roboto',
            textAlign: 'left',
            marginBottom: '20px',
            marginLeft: '0px',
            marginTop: '0px',
          }}
        >
          COLLABORATORS
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            gap: '20px',
            flexWrap: 'wrap',
            borderRadius: '12px',
          }}
        >
          {collaborator_renders}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center',
          borderRadius: '12px',
          padding: '20px',
          margin: '0px 20px 10px 20px',
          height: '160px',
          //border: '1px solid #00CFFF',
          alignContent: 'flex-start',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignContent: 'flex-start',
          }}
        >
          <div
            style={{
              font: 'normal normal 500 16px Roboto',
              width: '100%',
              textAlign: 'left',
              marginBottom: '20px',
              marginLeft: '0px',
              marginTop: '0px',
            }}
          >
            {data.backerTokenLiveInfo ? 'BACKERS' : 'PROJECT'}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '20px',

              alignContent: 'center',
              //marginLeft: '50px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignContent: 'center',
                gap: '20px',

                borderRadius: '12px',
                marginLeft: '50px',
              }}
            >
              {backer_renders}
              <div
                style={{
                  marginLeft: '0px',
                  padding: '20px',
                  font: 'normal normal 500 24px Roboto',
                  color: '#93B5C6',
                }}
              >
                {data.backerTokenLiveInfo
                  ? data.backerTokenLiveInfo.holders.length > 3
                    ? '+' +
                      (data.backerTokenLiveInfo.holders.length - 3) +
                      ' more'
                    : null
                  : null}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              font: 'normal normal 500 24px Roboto',
              color: '#93B5C6',
              // paddingTop: '20px',
            }}
          ></div>
          <a
            href={
              data.project
                ? `${process.env.REACT_APP_URL}${PROJECT_ROUTE.PROJECT}/${data.project._id}`
                : ''
            }
            style={{
              alignSelf: 'center',
              font: 'normal normal 500 32px Roboto',
              color: '#0E4799',
              textDecoration: 'underline',
            }}
          >
            {data.project ? data.project.name : ''}
          </a>
        </div>
      </div>
    </div>
  );
}
