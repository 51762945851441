import React, {Component} from 'react';
import {Layout} from 'antd';
import {Debounce} from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import {ThemeProvider} from 'styled-components';
import appActions from '../../redux/app/actions';
import Sidebar from '../Sidebar/Sidebar';
import DashboardRoutes from './DashboardRoutes';
import themes from '../../settings/themes';
import {themeConfig} from '../../settings';
import AppHolder from '../../../src/commonStyle';
import '../../../src/global.css';
import {useSelector, useDispatch} from 'react-redux';
import useWindowSize from '@iso/lib/hooks/useWindowSize';
import Topbar from '../Topbar/Topbar';
import {useTranslation, Trans} from 'react-i18next';

const {toggleAll} = appActions;

const {Content, Footer} = Layout;
const styles = {
  layout: {flexDirection: 'row', overflowX: 'hidden'},
  content: {
    padding: '70px 0 0',
    flexShrink: '0',
    background: '#f1f3f6',
    position: 'relative',
  },
  footer: {
    background: '#ffffff',
    textAlign: 'center',
    borderTop: '1px solid #ededed',
  },
};

const lngs = {
  en: {nativeName: 'English'},
  ar: {nativeName: 'Arabic'},
};

export default function Dashboard() {
  const dispatch = useDispatch();
  const appHeight = useSelector(state => state.App.height);
  const {height} = useWindowSize();
  const {t, i18n} = useTranslation();

  return (
    <ThemeProvider theme={themes[themeConfig.theme]}>
      <AppHolder>
        <Topbar state={'private'} url={'private'} />
        <Layout style={{height: appHeight}}>
          <Debounce time="1000" handler="onResize">
            <WindowResizeListener
              onResize={windowSize =>
                toggleAll(windowSize.windowWidth, windowSize.windowHeight)
              }
            />
          </Debounce>
          <Layout style={{flexDirection: 'row', overflowX: 'hidden'}}>
            <Sidebar />
            <Layout
              className="isoContentMainLayout"
              style={{
                height: height,
              }}
            >
              <Content
                className="isomorphicContent"
                style={{
                  padding: '70px 0 0',
                  flexShrink: '0',
                  background: '#f1f3f6',
                  position: 'relative',
                }}
              >
                <DashboardRoutes />
              </Content>
              <Footer
                style={{
                  background: '#ffffff',
                  textAlign: 'center',
                  borderTop: '1px solid #ededed',
                  color: '#134B9B',
                }}
              >
                {t('footer_text')}
                <br />
                <a href="https://www.fandefi.com" target="_blank">
                  www.fandefi.com
                </a>
              </Footer>
            </Layout>
          </Layout>
        </Layout>
      </AppHolder>
    </ThemeProvider>
  );
}
