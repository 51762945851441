import styled from 'styled-components';
import WithDirection from '../../settings/withDirection';
import {palette} from 'styled-theme';

const ArtifactDetailStyleWrapper = styled.div`
  width: 100%;

  .topbar {
    overflow: hidden;
    background: #ffffff;
    background-repeat: no-repeat;
    position: relative;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 9;
    padding: 0 20px;
    height: 65px;
    border-bottom: 1px solid #00000029;
    nav {
      z-index: 9;
    }
    .logo_ {
      display: flex;
      img {
        width: 82px;
      }
    }
    .metamask {
      background-color: #000000;
      border-radius: 75px;
      width: 150px;
      margin: auto;
      padding-left: 10px;
      display: flex;
      margin-right: 10px;
    }
  }
  .artifactWrapper {
    border-bottom: 1px solid #00000029;
    display: flex;
    text-align: center;

    .columnStyle {
      border-right: 1px solid #00000029;

      @media only screen and (max-width: 576px) {
        border-right: none;
      }
    }

    .artifactImageWrapper {
      .enlargeButtonWrapper {
        margin: 10px;
        display: flex;
        justify-content: flex-end;

        button {
          width: 36px;
          height: 36px;
          border: 1px solid #707070;
          border-radius: 18px;
          background-color: #fff !important;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: none;
          padding: 0px;

          > img {
            width: 25px;
            height: 25px;
          }

          &:hover {
            border-color: 1px solid #707070 !important;
            background-color: #fff !important;
          }
        }
      }

      img {
        width: 100%;
        border-radius: 8px;
      }
    }

    .artifactDetailsWrapper {
      @media only screen and (max-width: 576px) {
        border-top: 1px solid #00000029;
      }

      .shareButtonWrapper {
        display: flex;
        justify-content: flex-end;

        button {
          width: 38px;
          height: 33px;
          border: 1px solid #707070;
          border-radius: 4px;
          background-color: #fff !important;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: none;
          padding: 0px;

          > img {
            width: 20px;
            height: 20px;
          }

          &:hover {
            border-color: 1px solid #707070 !important;
            background-color: #fff !important;
          }
        }
      }

      .artifactDetailContainer {
        .artifactBasicDetails {
          padding: 20px 20px;
          display: flex;
          align-items: flex-start;

          .artifactTitleWrapper {
            width: 100%;
            text-align: left;

            .artifactTitle {
              font: normal normal 900 36px/51px Roboto;
              color: #292929;
            }

            .artifactId {
              font: normal normal bold 22px/32px Roboto;
              color: #00cfff;
            }
          }

          .artifactEditionWrapper {
            diplay: flex;
            flexdirection: row;
            align-items: center;
            text-align: center;

            padding: 5px 10px 5px 10px;
            aligncontent: center;
            border-radius: 8px;
            border: 1px solid #00cfff;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
            .artifactEditionLabel {
              font: normal normal 500 11px Roboto;
              color: #292929;
            }
            .artifactEditionNo {
              font: normal normal 500 32px Roboto;
              color: #292929;
            }
          }
        }

        .artifactArtistDetails {
          text-align: left;
          padding: 0px 20px;
          margin-top: 10px;
          margin-bottom: 30px;

          .artifactArtist {
            font: normal normal bold 22px/50px Roboto;
            color: #292929;
          }

          .artifactArtistDesc {
            font: normal normal normal 14px/18px Roboto;
            color: #292929;
          }
        }

        .artifactButtonsWrapper {
          padding: 0px 20px;
          display: flex;
          flex-direction: column;
          align-items: left;

          a {
            width: 250px;
          }

          button {
            width: 250px;
            height: 46px;
            border-radius: 6px !important;
            border-color: #ffffff !important;
            background-color: #ffffff !important;
            box-shadow: 0px 0px 12px #00000029;
            text-align: start;
            padding: 0px 15px;

            > img {
              width: 30px;
              height: 30px;
            }

            > span {
              color: #292929;
              font: normal normal bold 12px/16px Roboto;
              padding-left: 15px;
            }

            &:hover {
              border-color: #ffffff !important;
              background-color: #ffffff !important;

              > span {
                color: #292929;
                font: normal normal bold 12px/16px Roboto;
              }
            }
          }
        }
      }

      .artifactSaleWrapper {
        margin-top: 25px;
        padding: 20px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px -9px 14px #00000017;
        display: flex;
        height: 120px;
        flex-wrap: wrap;
        justify-content: center;

        .artifactPriceDetails {
          display: flex;
          flex-direction: column;

          .artifactPrice {
            font: normal normal bold 26px/33px Roboto;
            color: #0e4799;
          }

          .artifactPriceInUSD {
            font: normal normal bold 12px/16px Roboto;
            color: #00cfff;
          }
        }

        .artifactBuyButtonWrapper {
          display: flex;
          align-items: center;
          button {
            border-radius: 4px important;
            font: normal normal bold 16px/5px Roboto;
            border-color: #00cfff !important;
            background-color: #00cfff !important;
            box-shadow: 0px 0px 32px #00000017;
            color: #ffffff;

            &:hover {
              border-color: #00c0ed !important;
              background-color: #00c0ed !important;
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  .modal_mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
  }

  .addPopup {
    max-width: 500px;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
    top: calc((100vh - 634px) / 2);
    position: relative;
    background: #ffffff;
    text-align: center;
    padding: 20px 15px 29px 15px;
    font-size: 13px;
    color: #414141;
    line-height: 1.5;
    border-radius: 9px;
    @media only screen and (max-width: 480px) {
      border-radius: 0px;
    }

    .popup_header {
      font-size: 16px;
      color: #414141;
      font-weight: 500;
      border-bottom: 1px solid #eaeaea;
      padding-bottom: 15px;

      h4 {
        text-align: left;
        color: #414141;
      }
      span {
        position: absolute;
        right: 20px;
        top: 19px;
        font-size: 19px;
        cursor: pointer;
        &:before {
          content: '\\292C';
          position: relative;
          bottom: 4px;
          left: 3px;
          color: gray;
        }
      }
    }
    .main {
      max-height: 490px;
      overflow-y: auto;
      display: flex;
      justify-content: center;
      margin: 30px 0px;

      span {
        color: ${palette('gray', 9)};
        font-weight: normal;
        font-size: 16px;
        text-align: center;
        display: block;
      }
    }

    .isoButtonWrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;

      .ant-btn {
        width: 100%;
        height: 50px;
        border-radius: 25px !important;
        border-color: #000 !important;
        background-color: #000 !important;
        padding: 0px 10px;
        max-width: 250px;
        min-width: 150px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        color: #ffffff;
        font-size: 18px;
        font-weight: bold;
        &:hover {
          border-color: #000 !important;
          background-color: #000 !important;
        }
      }
    }
  }
`;

export default WithDirection(ArtifactDetailStyleWrapper);
