import React, { lazy, Suspense } from 'react';
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import Loader from '@iso/components/utility/loader';
import { PRIVATE_ROUTE } from '../../route.constants';
import CustomSwitch from './../../customSwitch';

const routes = [
  {
    path: PRIVATE_ROUTE.DASHBOARD_LANDING,
    component: lazy(() => import('@iso/containers/Dashboard/home')),
  },
  {
    path: PRIVATE_ROUTE.DASHBOARD_HOME,
    component: lazy(() => import('@iso/containers/Dashboard/home')),
  },
  {
    path: PRIVATE_ROUTE.DASHBOARD_PROJECTS,
    component: lazy(() => import('@iso/containers/Dashboard/projects')),
  }, 
  {
    path: PRIVATE_ROUTE.DASHBOARD_ASSETS,
    component: lazy(() => import('@iso/containers/Dashboard/assets')),
  }, 
  {
    path: PRIVATE_ROUTE.DASHBOARD_LANDING_PAGES,
    component: lazy(() => import('@iso/containers/Dashboard/landingpages')),
  }, 
  {
    path: PRIVATE_ROUTE.DASHBOARD_FUNDS,
    component: lazy(() => import('@iso/containers/Dashboard/funds')),
  }, 
  {
    path: PRIVATE_ROUTE.DASHBOARD_FANS,
    component: lazy(() => import('@iso/containers/Dashboard/fans')),
  }, 
  {
    path: PRIVATE_ROUTE.DASHBOARD_MARKET_PLACE,
    component: lazy(() => import('@iso/containers/Dashboard/marketplace')),
  }, 
  {
    path: PRIVATE_ROUTE.DASHBOARD_CREATE_PROJECT,
    exact: false,
    component: lazy(() => import('@iso/containers/Projects/create_project')),
  },
  {
    path: PRIVATE_ROUTE.DASHBOARD_MY_ACCOUNT,
    exact: false,
    component: lazy(() => import('@iso/containers/Dashboard/myaccount')),
  },
];

export default function AppRouter() {
  const { url } = useRouteMatch();
  return (
    <Suspense fallback={<Loader />}>
      <CustomSwitch>
      {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute; 
          return (
            <Route 
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={singleRoute.path}
              {...otherProps} 
            />
          );
        })}
      </CustomSwitch>
    </Suspense>
  );
}