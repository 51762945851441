export const actions = {
  SET_WEB3_PROVIDER: 'SET_WEB3_PROVIDER',
  SET_WEB3_USER: 'SET_WEB3_USER',
  SET_WEB3_NETWORK: 'SET_WEB3_NETWORK',
  setWeb3Provider: web3Provider => {
    return {
      type: actions.SET_WEB3_PROVIDER,
      web3Provider,
    };
  },
  setWeb3User: web3User => {
    return {
      type: actions.SET_WEB3_USER,
      web3User,
    };
  },
  setWeb3Network: web3Network => {
    return {
      type: actions.SET_WEB3_NETWORK,
      web3Network,
    };
  }
};
